import * as React from 'react'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBars,
    faFolder,
    faStar,
    faBellSlash,
    faPenToSquare,
    faFileExport,
    faTrash,
    faObjectSubtract,
    faBell,
} from '@fortawesome/pro-duotone-svg-icons'

import ShowEntries from '../../../../Components/Elements/ShowEntries.jsx'
import MultiContentDossierDelete from '../../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierDelete.jsx'
import ContentDossierExport from '../../../../Components/Pages/DefDossier/DefDossierToolbar/ContentDossierExport.jsx'
import MultiContentDossierArchive from '../../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierArchive'
import MultiContentDossierMerge from '../../../../Components/Pages/DefDossier/MergeDossier/index'
import MultiContentDossierSubscribe from '../../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierSubscribe.jsx'
import MultiContentDossierFavorite from '../../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierFavorite.jsx'
import MultiContentDossierMassUpdate from '../../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierMassUpdate.jsx'

interface ActionButtonsProps {
    selected: any[]
    selectedDossiers: any[]
    isAllowedDd: (permission: string) => boolean
    hasSubscriptions: boolean
    dearchivedDossiers: boolean
    archivedDossiers: boolean
    kedo: any
    props: any
    mode: string
    dossiers: Array<any>
    pager: any
    limit: any
    handleEntriesChange: any
    onDeleteSuccess: any
    defDossierId: number
    getSearchFilter: any
    fetchDossierResults: any
    subscriptions: Array<any>
    favorites: Array<any>
    contents: any
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
    selected,
    selectedDossiers,
    isAllowedDd,
    hasSubscriptions,
    dearchivedDossiers,
    archivedDossiers,
    kedo,
    props,
    mode,
    dossiers,
    pager,
    limit,
    handleEntriesChange,
    onDeleteSuccess,
    defDossierId,
    getSearchFilter,
    fetchDossierResults,
    subscriptions,
    favorites,
    contents,
}) => {
    const [showActionModal, setShowActionModal] = useState('')
    if (selected.length === 0) return null

    const handleClick = (event: React.MouseEvent, action: string) => {
        event.preventDefault()
        if (selectedDossiers.length <= 0) {
            alert(kedo.t('Select dossiers first'))
            return
        }
        setShowActionModal(action)
    }

    return (
        <>
            <ButtonGroup size="sm">
                {isAllowedDd('delete') && (
                    <Button
                        size="sm"
                        variant="outline-secondary"
                        onClick={(e) => handleClick(e, 'delete')}
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />{' '}
                        {kedo.t('Delete')}
                    </Button>
                )}
                {(isAllowedDd('export') ||
                    isAllowedDd('export_word') ||
                    isAllowedDd('export_csv') ||
                    isAllowedDd('export_pdf') ||
                    isAllowedDd('export_html')) && (
                    <Button
                        size="sm"
                        variant="outline-secondary"
                        onClick={(e) => handleClick(e, 'export')}
                    >
                        <FontAwesomeIcon icon={faFileExport} className="mr-2" />{' '}
                        {kedo.t('Export')}
                    </Button>
                )}
                <Dropdown
                    as={ButtonGroup}
                    onSelect={(eventKey) =>
                        setShowActionModal(eventKey as string)
                    }
                >
                    <Dropdown.Toggle
                        variant="outline-secondary"
                        id="dropdown-actions"
                    >
                        <FontAwesomeIcon icon={faBars} className="mr-2" />{' '}
                        {kedo.t('More actions')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="subscribe">
                            <FontAwesomeIcon icon={faBell} className="mr-2" />{' '}
                            {kedo.t('Subscription')}
                        </Dropdown.Item>
                        {hasSubscriptions && (
                            <Dropdown.Item eventKey="stopsubscription">
                                <FontAwesomeIcon
                                    icon={faBellSlash}
                                    className="mr-2"
                                />{' '}
                                {kedo.t('Stop subscription')}
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item eventKey="favorite">
                            <FontAwesomeIcon icon={faStar} className="mr-2" />{' '}
                            {kedo.t('Favorite')}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="removefavorite">
                            <FontAwesomeIcon icon={faStar} className="mr-2" />{' '}
                            {kedo.t('Remove favorite')}
                        </Dropdown.Item>
                        {isAllowedDd('merge') && selected.length === 2 && (
                            <Dropdown.Item eventKey="merge">
                                <FontAwesomeIcon
                                    icon={faObjectSubtract}
                                    className="mr-2"
                                />{' '}
                                {kedo.t('Merge')}
                            </Dropdown.Item>
                        )}
                        {isAllowedDd('massupdate') && (
                            <Dropdown.Item eventKey="massupdate" disabled>
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    className="mr-2"
                                />{' '}
                                {kedo.t('Mass update')}
                            </Dropdown.Item>
                        )}
                        {dearchivedDossiers && isAllowedDd('archive') && (
                            <Dropdown.Item eventKey="archive">
                                <FontAwesomeIcon
                                    icon={faFolder}
                                    className="mr-2"
                                />{' '}
                                {kedo.t('Archive')}
                            </Dropdown.Item>
                        )}
                        {archivedDossiers && isAllowedDd('archive') && (
                            <Dropdown.Item eventKey="dearchive">
                                <FontAwesomeIcon
                                    icon={faFolder}
                                    className="mr-2"
                                />{' '}
                                {kedo.t('Dearchive')}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
            <div className={''}>
                {props.multiAdd ? <>{props.multiAdd}&nbsp;</> : null}
                {(mode === 'embeddedList' || mode === 'block') &&
                props.addDossier ? (
                    <>{props.addDossier}&nbsp;</>
                ) : null}
                {mode === 'embeddedList' && props.filterDossier ? (
                    <>{props.filterDossier}&nbsp;</>
                ) : null}
                {isAllowedDd('export') &&
                dossiers?.length > 0 &&
                selected?.length <= 0 ? (
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            setShowActionModal('all-export')
                        }}
                        title={kedo.t('Export')}
                        variant={'outline-secondary'}
                    >
                        <FontAwesomeIcon
                            className={'mr-2'}
                            icon={faFileExport}
                        />
                    </Button>
                ) : null}
                {dossiers?.length > 0 && selected?.length <= 0 ? (
                    <>
                        <ShowEntries
                            pager={pager}
                            value={limit}
                            onChange={handleEntriesChange}
                        />
                        &nbsp;
                    </>
                ) : null}
                {showActionModal === 'delete' ? (
                    <MultiContentDossierDelete
                        kedo={kedo}
                        showModal={true}
                        size={'sm'}
                        onCancel={() => {
                            setShowActionModal('')
                        }}
                        onClose={() => {
                            setShowActionModal('')
                            onDeleteSuccess()
                        }}
                        items={selected}
                        mode={mode}
                    />
                ) : null}
                {showActionModal === 'all-export' ? (
                    <ContentDossierExport
                        showModal={showActionModal === 'all-export'}
                        defDossierId={defDossierId}
                        disablePDF={true}
                        disableWord={true}
                        filter={getSearchFilter}
                        size={'sm'}
                        kedo={kedo}
                        onCancel={() => {
                            setShowActionModal('')
                        }}
                        onClose={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        selected={[]}
                        mode={
                            mode === 'embeddedList' || mode === 'block'
                                ? 'all'
                                : null
                        }
                    />
                ) : null}
                {showActionModal === 'export' ? (
                    <ContentDossierExport
                        showModal={showActionModal === 'export'}
                        defDossierId={defDossierId}
                        filter={getSearchFilter}
                        size={'sm'}
                        kedo={kedo}
                        onCancel={() => {
                            setShowActionModal('')
                        }}
                        onClose={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        selected={selected}
                        mode={
                            mode === 'embeddedList' || mode === 'block'
                                ? 'all'
                                : null
                        }
                    />
                ) : null}
                {showActionModal === 'archive' ||
                showActionModal === 'dearchive' ? (
                    <MultiContentDossierArchive
                        kedo={kedo}
                        showModal={true}
                        onClose={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        archived={showActionModal === 'archive'}
                        checked={selected}
                        mode={mode}
                    />
                ) : null}
                {showActionModal === 'merge' ? (
                    <MultiContentDossierMerge
                        props={props}
                        kedo={kedo}
                        defDossierId={defDossierId}
                        closeModal={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        idDossiers={selectedDossiers.map((item) => item.id)}
                    />
                ) : null}
                {showActionModal === 'subscribe' ||
                showActionModal === 'stopsubscription' ? (
                    <MultiContentDossierSubscribe
                        kedo={kedo}
                        showModal={true}
                        onClose={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        subscriptions={subscriptions}
                        items={selected}
                        showActionModal={showActionModal}
                        mode={mode}
                    />
                ) : null}
                {showActionModal === 'favorite' ||
                showActionModal === 'removefavorite' ? (
                    <MultiContentDossierFavorite
                        kedo={kedo}
                        showModal={true}
                        showActionModal={showActionModal}
                        onClose={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        favorites={favorites}
                        items={selected}
                    />
                ) : null}
                {showActionModal === 'massupdate' ? (
                    <MultiContentDossierMassUpdate
                        kedo={kedo}
                        showModal={true}
                        onClose={() => {
                            setShowActionModal('')
                            fetchDossierResults()
                        }}
                        mode={mode}
                        selectedDossierIds={
                            selectedDossiers?.length > 0
                                ? selectedDossiers?.map((ids) => ids.id)
                                : dossiers?.map((ids) => ids.id)
                        }
                        contents={contents}
                    />
                ) : null}
            </div>
        </>
    )
}

export default ActionButtons
