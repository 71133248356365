import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

import SelectRC, { Option } from 'rc-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons/faCaretDown'

class DisplayItemFileSettings extends Component {
    constructor(props) {
        super(props)

        const kedo = props.kedo
        const selected = []

        const fileTypes = props.item?.settings?.file_types
        if (Array.isArray(fileTypes)) {
            fileTypes.forEach((item) => {
                let label = item.charAt(0).toUpperCase() + item.slice(1)
                if (item === 'images') {
                    label = kedo.t('Images')
                }
                if (item === 'documents') {
                    label = kedo.t('Text documents')
                }
                if (item === 'spreadsheet') {
                    label = kedo.t('Spreadsheet documents')
                }
                if (item === 'presentation') {
                    label = kedo.t('Presentation documents')
                }

                selected.push({
                    label: label,
                    value: item,
                })
            })
        }

        const options = [
            // {value: '', label: 'Everything'},
            { value: 'images', label: kedo.t('Images') },
            { value: 'audio', label: 'Audio' },
            { value: 'video', label: 'Videos' },
            { value: 'documents', label: kedo.t('Text documents') },
            { value: 'spreadsheet', label: kedo.t('Spreadsheet documents') },
            { value: 'presentation', label: kedo.t('Presentation documents') },
        ]

        this.state = {
            multiple: this.props.item?.settings?.multiple ? true : false,
            file_preview: this.props.item?.settings?.file_preview
                ? true
                : false,
            preview_direct: this.props.item?.settings?.preview_direct
                ? true
                : false,
            preview_width: this.props.item?.settings?.preview_width
                ? this.props.item.settings.preview_width
                : null,
            preview_height: this.props.item?.settings?.preview_height
                ? this.props.item.settings.preview_height
                : null,
            signature: this.props.item?.settings?.signature ? true : false,
            options: options,
            selected: this.props.item?.settings?.file_types ? selected : [],
        }

        this.onChange = this.onChange.bind(this)
        this.onNormalChange = this.onNormalChange.bind(this)
        this.onChangeFileTypeOptions = this.onChangeFileTypeOptions.bind(this)
    }

    onChangeFileTypeOptions(options) {
        const selectedOptions = options || []
        this.setState({ selected: selectedOptions })

        const values = selectedOptions.map((item) => item.value)
        this.props.onChange('file_types', values)
    }

    onChange(event) {
        if (event.target.checked === true) {
            this.setState({ [event.target.name]: true })
            this.props.onChange(event.target.name, true)
        } else {
            this.setState({ [event.target.name]: false })
            this.props.onChange(event.target.name, false)
        }
    }

    onNormalChange(event) {
        this.setState({ [event.target.name]: event.target.value })
        this.props.onChange(event.target.name, event.target.value)
    }

    render() {
        const filteredOptions = this.state.options.filter(
            (option) =>
                !this.state.selected.some(
                    (selected) => selected.value === option.value
                )
        )

        return (
            <div>
                <Form.Group>
                    <Form.Label>
                        {this.props.kedo.t('Restrict file uploads')}
                    </Form.Label>
                    <SelectRC
                        className="w-100 custom-select-react"
                        id={`file-settings`}
                        mode="multiple"
                        allowClear
                        onChange={(selectedItems) => {
                            const selectedOptions = selectedItems.map(
                                (selectedItem) =>
                                    this.state.options.find(
                                        (option) =>
                                            option.value === selectedItem.value
                                    )
                            )
                            this.onChangeFileTypeOptions(selectedOptions)
                        }}
                        suffixIcon={
                            <FontAwesomeIcon size="xl" icon={faCaretDown} />
                        }
                        placeholder={
                            this.props.kedo.t('Select an option') + '...'
                        }
                        value={this.state.selected.map((item) => ({
                            value: item.value,
                            label: item.label,
                        }))}
                        labelInValue
                        notFoundContent={this.props.kedo.t('No items found')}
                    >
                        {filteredOptions.map((option) => (
                            <Option key={option.id} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </SelectRC>
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        checked={this.state.multiple}
                        id={'radio-multiple-files'}
                        name={'multiple'}
                        label={this.props.kedo.t('Allow multiple files')}
                        onChange={(event) => this.onChange(event)}
                        type="checkbox"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        checked={this.state.file_preview}
                        id={'radio-file-preview'}
                        name={'file_preview'}
                        label={this.props.kedo.t('Show file preview')}
                        onChange={(event) => this.onChange(event)}
                        type="checkbox"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        checked={this.state.preview_direct}
                        id={'radio-preview-direct'}
                        name={'preview_direct'}
                        label={this.props.kedo.t('Direct preview')}
                        onChange={(event) => this.onChange(event)}
                        type="checkbox"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        checked={this.state.signature}
                        id={'radio-signature'}
                        name={'signature'}
                        label={this.props.kedo.t('Signature')}
                        onChange={(event) => this.onChange(event)}
                        type="checkbox"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{this.props.kedo.t('Width')}</Form.Label>
                    <Form.Control
                        value={this.state.preview_width}
                        id={'radio-preview-width'}
                        name={'preview_width'}
                        type={'number'}
                        onChange={(event) => this.onNormalChange(event)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{this.props.kedo.t('Height')}</Form.Label>
                    <Form.Control
                        value={this.state.preview_height}
                        id={'radio-preview-width'}
                        name={'preview_height'}
                        type={'number'}
                        onChange={(event) => this.onNormalChange(event)}
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemFileSettings
