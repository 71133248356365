import * as React from 'react'

import SelectRC, { Option } from 'rc-select'
import { useContext, useEffect, useState } from 'react'
import { KeDoContext } from '../../../kedo-context.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons/faCaretDown'

const UserSelect = ({ kedo, onChangeUsers, currentUsers }) => {
    //const kedo = useContext(KeDoContext)

    const [values, setValues] = useState(currentUsers ?? [])
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(100)
    const [options, setOptions] = useState([])

    const handleReceiversChange = (selectedItems) => {
        if (!selectedItems || !selectedItems.map) {
            setValues([])
            return
        }

        setValues(selectedItems)
        if (onChangeUsers) {
            onChangeUsers(selectedItems)
        }
    }

    useEffect(() => {
        getOptions()
        if (onChangeUsers) {
            onChangeUsers(values)
        }
    }, [values])

    const getOptions = (search) => {
        setLoading(true)
        let params = {
            params: {
                limit: limit,
            },
        }

        if (search.length > 0) {
            params.params.name = search
        }
        return kedo
            .api()
            .get(kedo.api().getUserEndpoint(), params)
            .then(function (response) {
                if (!response.data.results) {
                    setOptions([])
                }

                setOptions(
                    response.data.results.map((user) => ({
                        id: user.id,
                        value: user.id,
                        label: user.profile
                            ? user.profile.last_name +
                              ', ' +
                              user.profile.first_name
                            : user.username,
                    }))
                )
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const filteredOptions = options.filter(
        (option) => !values.some((selected) => selected.value === option.value)
    )

    return (
        <>
            <SelectRC
                className="w-100 custom-select-react"
                mode={'multiple'}
                placeholder={kedo.t('Search') + '...'}
                value={values.filter((option) =>
                    options.some((item) => item.value === option.value)
                )}
                onChange={(selectedItems) => {
                    const selectedOptions = selectedItems
                        .map((selectedItem) => {
                            const foundOption = options.find((option) => {
                                return option.value === selectedItem
                            })
                            return foundOption
                        })
                        .filter(Boolean)
                    handleReceiversChange(selectedOptions)
                }}
                suffixIcon={<FontAwesomeIcon size="xl" icon={faCaretDown} />}
                notFoundContent={kedo.t('No items found')}
            >
                {filteredOptions.map((option) => (
                    <Option key={option.id} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </SelectRC>
        </>
    )
}

export default UserSelect
