import React, { Component } from 'react'
import { Form, Alert, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faTimes, faPlus } from '@fortawesome/pro-duotone-svg-icons'

class DisplayItemDateSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show_slider:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_slider
                    ? true
                    : false,
            show_ago:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.show_ago
                    : null,
            deadline:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.deadline
                    ? this.props.item.settings.deadline
                    : null,
            showColorPicker: false,
            default_current_date:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.default_current_date
                    : null,
        }

        this.addFieldToDate = this.addFieldToDate.bind(this)
        this.removeFieldFromDate = this.removeFieldFromDate.bind(this)
        this.deadlineDate = this.deadlineDate.bind(this)
    }

    addFieldToDate() {
        const newField = { number: 1, type: 'day', when: 'from now', color: '' }
        let deadline = this.state.deadline
        if (!Array.isArray(deadline)) {
            deadline = []
        }
        deadline.push(newField)

        this.setState({ deadline: deadline })
        this.props.onChange('deadline', deadline)
    }

    removeFieldFromDate(index) {
        let deadline = this.state.deadline
        deadline.splice(index, 1)
        this.setState({ deadline: deadline })
        this.props.onChange('deadline', deadline)
    }

    deadlineDate(index) {
        const typesSingular = [
            { type: 'Day', value: 'day' },
            { type: 'Week', value: 'week' },
            { type: 'Month', value: 'month' },
            { type: 'year', value: 'year' },
        ]

        const typesPlural = [
            { type: 'days', value: 'day' },
            { type: 'Weeks', value: 'week' },
            { type: 'Months', value: 'month' },
            { type: 'Years', value: 'year' },
        ]

        return (
            <>
                <Row>
                    <Col>
                        <div>
                            <FontAwesomeIcon
                                color={this.state.deadline[index].color}
                                style={{ cursor: 'pointer' }}
                                icon={faCircle}
                                onClick={() => this.toggleColorPicker()}
                            />
                            <Form.Control
                                type="color"
                                value={this.state.deadline[index].color}
                                onChange={(e) => this.handleColorChange(index, e.target.value)}
                                title="Choose your color"
                                style={{
                                    display: this.state.showColorPicker ? 'block' : 'none',
                                }}
                            />
                            {' '}{`#${index + 1} ${this.props.kedo.t('reminder')}`}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control
                            as="select"
                            onChange={(e) => {
                                const updatedDeadline = this.state.deadline.map(
                                    (item, i) => {
                                        if (i === index) {
                                            return {
                                                ...item,
                                                number: parseInt(e.target.value),
                                            }
                                        }
                                        return item
                                    }
                                )

                                this.setState({ deadline: updatedDeadline })
                                this.props.onChange('deadline', updatedDeadline)
                            }}
                            value={this.state.deadline[index].number}
                        >
                            {Array.from({ length: 31 }, (_, index) => (
                                <option value={index + 1} key={index + 1}>
                                    {index + 1}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Control
                            as="select"
                            onChange={(e) => {
                                const updatedDeadline = this.state.deadline.map(
                                    (item, i) => {
                                        if (i === index) {
                                            return { ...item, type: e.target.value }
                                        }
                                        return item
                                    }
                                )

                                this.setState({ deadline: updatedDeadline })
                                this.props.onChange('deadline', updatedDeadline)
                            }}
                            value={this.state.deadline[index].type}
                        >
                            {this.state.deadline.map((item, i) => {
                                if (item.number === 1 && i === index) {
                                    return typesSingular.map((type) => (
                                        <option value={type.value} key={type.value}>
                                            {this.props.kedo.t(type.type)}
                                        </option>
                                    ))
                                } else if (item.number > 1 && i === index) {
                                    return typesPlural.map((type) => (
                                        <option value={type.value} key={type.value}>
                                            {this.props.kedo.t(type.type)}
                                        </option>
                                    ))
                                }
                                return null
                            })}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Control
                            as="select"
                            onChange={(e) => {
                                const updatedDeadline = this.state.deadline.map(
                                    (item, i) => {
                                        if (i === index) {
                                                return { ...item, when: e.target.value}
                                        }
                                        return item
                                    }
                                )

                                this.setState({ deadline: updatedDeadline })
                                this.props.onChange('deadline', updatedDeadline)
                            }}
                            value={this.state.deadline[index].when}
                        >
                            <option value="before">
                                {this.props.kedo.t('time_before')}
                            </option>
                            <option value="after">
                                {this.props.kedo.t('time_after')}
                            </option>
                        </Form.Control>
                    </Col>
                    <Col>
                        <div
                            onClick={() => this.removeFieldFromDate(index)}
                            className="input-group-append"
                            id="button-addon4"
                        >
                            <button
                                className="btn btn-outline-danger"
                                type="button"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    handleColorChange = (index, color) => {
        const updatedDeadline = this.state.deadline.map((item, i) => {
            if (i === index) {
                return { ...item, color: color }
            }
            return item
        })

        this.setState({ deadline: updatedDeadline })
        this.props.onChange('deadline', updatedDeadline);
    }

    toggleColorPicker() {
        this.setState({ showColorPicker: !this.state.showColorPicker })
    }

    render() {
        return (
            <div>
                <h3>{this.props.kedo.t('Settings')}</h3>
                <Form.Group>
                    <Form.Check
                        checked={this.state.show_slider}
                        id={'show_slider'}
                        name={'show_slider'}
                        label={this.props.kedo.t('Show slider')}
                        onChange={(e) => {
                            this.setState({ show_slider: e.target.checked })
                            this.props.onChange('show_slider', e.target.checked)
                        }}
                    />
                    <Form.Check
                        checked={this.state.show_ago === true}
                        id={'radio-show-ago'}
                        name={'show_ago'}
                        label={this.props.kedo.t('Show ago')}
                        onChange={(e) => {
                            this.setState({ show_ago: e.target.checked })
                            this.props.onChange('show_ago', e.target.checked)
                        }}
                        type="checkbox"
                    />
                    <Form.Check
                        checked={this.state.deadline != null}
                        id={'radio-deadline'}
                        name={'deadline'}
                        label={
                            this.state.deadline ? (
                                <div>
                                    {this.props.kedo.t('Deadline')}{' '}
                                    <Button
                                        size={'sm'}
                                        onClick={() => this.addFieldToDate()}
                                    >
                                        <FontAwesomeIcon
                                            size="xs"
                                            icon={faPlus}
                                        />
                                    </Button>
                                </div>
                            ) : (
                                this.props.kedo.t('Deadline')
                            )
                        }
                        onChange={(e) => {
                            let data = e.target.checked ? {} : null
                            this.setState({ deadline: data })
                            this.props.onChange('deadline', data)
                        }}
                        type="checkbox"
                    />
                    {this.state.deadline != null ? (
                        <>
                            {this.state.deadline?.length > 0 &&
                                this.state.deadline.map((item, index) => (
                                    <Form.Group key={index} className="mb-3">
                                        {this.deadlineDate(index)}
                                    </Form.Group>
                                ))}
                        </>
                    ) : null}
                    <Form.Check
                        checked={this.state.default_current_date === true}
                        id={'checkbox-default_current_date'}
                        name={'default_current_date'}
                        label={this.props.kedo.t(
                            'Use current date/time as default value'
                        )}
                        onChange={(e) => {
                            this.setState({
                                default_current_date: e.target.checked,
                            })
                            this.props.onChange(
                                'default_current_date',
                                e.target.checked
                            )
                        }}
                        type="checkbox"
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemDateSettings
