import * as React from 'react'
import { createRef, RefObject, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Button, Alert, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { faCog } from '@fortawesome/pro-duotone-svg-icons/faCog'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'

import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import LoadingPage from '../../../Components/Elements/Loading/LoadingPage.jsx'
import DefaultGrid from '../../../Components/Grid/DefaultGrid.jsx'
import ContentDossierToolbar from '../../../Components/Pages/ContentDossier/ContentDossierToolbar/ContentDossierToolbar.jsx'
import LoadModuleContent from '../../../Components/Pages/ContentDossier/LoadModuleContent.jsx'
import ContentDossierInfo from '../../../Components/Pages/ContentDossier/ContentDossierInfo.jsx'
import VerticalPageTemplate from '../../../Components/Pages/ContentDossier/ContentDossierTemplates/VerticalPageTemplate.jsx'
import DefaultPageTemplate from '../../../Components/Pages/ContentDossier/ContentDossierTemplates/DefaultPageTemplate.jsx'
import DefaultHeader from '../../../Components/Pages/ContentDossier/ContentDossierTemplates/DefaultHeader.jsx'
import { toast } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'
import { useEntityService } from '../../../context/EntityServiceContext'
import { useDossierService } from '../../../context/DossierServiceContext'
import { useEnvironmentService } from '../../../context/EnvironmentServiceContext'
import DisplayItemHelper from '../../../util/DisplayItemHelper'
import EntityHelper from '../../../util/EntityHelper'
import BagHelper from '../../../util/BagHelper'
import { useSocketService } from '../../../context/SocketServiceContext'

const DetailContentDossier = ({ kedo, props }) => {
    const { handlePermissions } = props
    const socketService = useSocketService()
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const [edit, setEdit] = useState(props?.setEdit)
    const [defdossier, setDefdossier] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isLoadingCustomCreds, setIsLoadingCustomCreds] = useState(false)
    const [subscribed, setSubscribed] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [hideEditButton, setHideEditButton] = useState(false)
    const [subSaveAndClose, setSubSaveAndClose] = useState(false)
    const [customCredsInitialized, setCustomCredsInitialized] = useState(false)
    const [isCustomCredEditAllowed, setIsCustomCredEditAllowed] =
        useState(false)
    const [ignoreWarning, setIgnoreWarning] = useState(false)
    const [favorite, setFavorite] = useState(false)
    const [fetchPosition, setFetchPosition] = useState(null)
    const [showExisting, setShowExisting] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [existingUser, setExistingUser] = useState(null)
    const [loadingPosition, setLoadingPosition] = useState(null)
    const [activeTabKey, setActiveTabKey] = useState(null)
    const [existingDp, setExistingDp] = useState(null)
    const [dossier, setDossier] = useState(null)
    const [loadModule, setLoadModule] = useState(null)
    const [pageError, setPageError] = useState(null)
    const [id, setId] = useState(
        props && props.dossierId ? props.dossierId : params.id
    )

    function renderModalHeader() {
        return getModalHeader()
    }

    const [existingConstraintType, setExistingConstraintType] = useState(null)
    const [content, setContent] = useState({})
    const [sidebarContent, setSidebarContent] = useState({})
    const [sidebarDisplayItems, setSidebarDisplayItems] = useState([])
    const [errors, setErrors] = useState({})
    const [items, setItems] = useState([])
    const [displayPositions, setDisplayPositions] = useState([])
    const [fetchedPositions, setFetchedPositions] = useState([])
    const [socketUsers, setSocketUsers] = useState([])
    const [onlineStatus, setOnlineStatus] = useState([])
    const refs = useRef([])
    let curRef = useRef()
    const entityService = useEntityService()
    const environmentService = useEnvironmentService()
    const dossierService = useDossierService()
    const toastOptions = {
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    } as ToastOptions

    const getCurrentRef = (): RefObject<any> => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    const isEmbeddedLinkField = (displayItem) => {
        if (!displayItem.def_dossier_link || !displayItem.def_dossier_link.id) {
            return false
        }

        if (displayItem.def_dossier_link.type === 'embeddedList') {
            return true
        }

        if (displayItem?.settings?.embedded_single_view === true) {
            return true
        }

        return false
    }

    const fetchTabContent = async (positionId, position) => {
        fetchedPositions.push(positionId)
        setFetchedPositions(fetchedPositions)
        setLoadingPosition(positionId)
        const dossierData = await dossierService.getDossier(id, positionId)

        setContentByResponse(dossierData.content)
        setLoadModule(
            position?.settings && position.settings.module_content
                ? position.settings.module_content
                : false
        )

        if (
            position.display_items.length === 1 &&
            isEmbeddedLinkField(position.display_items[0])
        ) {
            setHideEditButton(true)
        }

        setFetchPosition(false)
        setLoadingPosition(false)
    }

    const loadClientSocket = () => {
        socketService.dossier.join(id)

        socketService.dossier.usersListener((data) => {
            if (parseInt(id) === parseInt(data.dossierId)) {
                setSocketUsers(data.users)
            } else if (socketUsers) {
                setSocketUsers(null)
            }
        })
    }

    const fetchDisplayPositions = async (defDossierId, dosId) => {
        const dpUrl =
            kedo.api().getDisplayPositionEndpoint() +
            '?defDossier=' +
            defDossierId +
            '&limit=100&contentDossierId=' +
            dosId
        return await kedo.api().getCached(dpUrl)
    }

    const fetchCustomDisplayView = async (defDossier, dosId) => {
        const params: Record<string, unknown> = {
            defDossier: defDossier.id,
            dossierIds: dosId,
        }

        if (
            defDossier &&
            defDossier.settings &&
            isNaN(defDossier.settings.layout_sidebar)
        ) {
            params.view = defDossier.settings.layout_sidebar
        }

        if (
            defDossier &&
            defDossier.settings &&
            !isNaN(defDossier.settings.layout_sidebar)
        ) {
            params.displayPosition = defDossier.settings.layout_sidebar
        }

        return await kedo
            .api()
            .get(kedo.api().getContentDossierEndpoint(), { params: params })
    }

    function renderModal() {
        return getModalHeader()
    }

    const fetchContentDossier = async (id) => {
        return await dossierService.getDossier(id)
    }

    const fetchDossier = async (fetchPos?: any) => {
        setLoading(true)
        const dossierData = await fetchContentDossier(id)

        if (
            dossierData.dossier &&
            dossierData.dossier.environment_id !== kedo.env().getEnvironmentId()
        ) {
            environmentService
                .getEnvironment(dossierData.dossier.environment_id)
                .then((environment) => {
                    kedo.env().setEnvironment(environment)
                    window.location.reload()
                })
        }
        const dpResponse = await fetchDisplayPositions(
            dossierData.dossier.def_dossier_id,
            id
        )

        const entity = (await entityService.getEntity(
            dossierData.dossier.def_dossier_id
        )) as Entity

        const mainPos = dpResponse.data.results.find(
            (dpItem) => dpItem.type === 'main_content'
        )

        const dcpResponse = await fetchCustomDisplayView(entity, id)

        setDefdossier(entity)
        setSubscribed(dossierData.subscribed)
        setFavorite(dossierData.favorite)
        setDossier(dossierData.dossier)
        setContentByResponse(dossierData.content)

        const roleIds = kedo
            .env()
            .getCurrentEnvironmentRoles()
            .map((item) => item.id)

        //setItems(dossierData.items)
        setItems(
            DisplayItemHelper.filterViews(dossierData.items, [
                DisplayItemHelper.EDIT_VIEW,
                DisplayItemHelper.SHOW_EDIT_VIEW,
            ]).filter((item) => {
                if (
                    !item.permissions_type ||
                    kedo.isAdminOrEnvironmentAdmin()
                ) {
                    return true
                }

                if (item.permissions_type === 'INCLUDE_SELECTED_ROLES') {
                    if (
                        item.roles.filter((roleItem) =>
                            roleIds.includes(roleItem.role.id)
                        ).length > 0
                    ) {
                        return true
                    } else {
                        return false
                    }
                } else if (item.permissions_type === 'EXCLUDE_SELECTED_ROLES') {
                    if (
                        item.roles.filter((roleItem) =>
                            roleIds.includes(roleItem.role.id)
                        ).length <= 0
                    ) {
                        return true
                    } else {
                        return false
                    }
                }

                return true
            })
        )
        setLoading(false)
        setDisplayPositions(dpResponse.data.results)

        let selectedTab = ''

        if (!props.embeddedInlineSingle) {
            const queryParams = new URLSearchParams(window.location.search)
            selectedTab = queryParams.get('selectedTab')
        } else {
            selectedTab = props.selectedTab ?? mainPos.id
        }

        setTimeout(
            () => {
                let newTabKey = null

                if (selectedTab) {
                    newTabKey = selectedTab
                    handleTabSelect(selectedTab)
                } else {
                    if (props.quickEdit || props.quickShow) {
                        newTabKey = mainPos?.id || null
                    } else if (!activeTabKey && selectedTab) {
                        newTabKey = selectedTab
                    } else if (activeTabKey === null && selectedTab === null) {
                        newTabKey = mainPos?.id || null
                    } else {
                        newTabKey =
                            activeTabKey === selectedTab
                                ? selectedTab
                                : mainPos?.id || null
                    }
                }

                setActiveTabKey(newTabKey)
            },
            props.selectedTab === 'main_content' ? 300 : 0
        )

        setSidebarContent(dcpResponse.data.results.contents)
        setSidebarDisplayItems(dcpResponse.data.results.items)

        if (fetchPos && typeof fetchPos === 'function') {
            fetchPos()
        }
    }

    const handleTabSelect = async (key: string) => {
        const url = new URL(window.location.href)
        const positionId = parseInt(key)
        const position = displayPositions.find((item) => item.id === positionId)

        if (!props.embeddedInlineSingle) {
            url.searchParams.set('selectedTab', key)
            window.history.pushState({}, '', url)
        }

        if (position) {
            setActiveTabKey(key)
            setFetchPosition(positionId)
            await fetchTabContent(positionId, position)
        } else {
            setActiveTabKey(key)
        }
    }

    const renderExistingDossier = () => {
        return (
            <Modal
                centered
                show={true}
                size="lg"
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{existingUser.summary}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DetailContentDossier
                        props={{
                            existingUser: true,
                            modalized: true,
                            dossierId: existingUser.id,
                            disableBreadcrumb: true,
                        }}
                        kedo={props.kedo}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {existingConstraintType === 'warning' ? (
                        <Button onClick={() => props.history.goBack()}>
                            {kedo.t('Use this and close')}
                        </Button>
                    ) : null}
                    <Button
                        onClick={() => setShowModal(false)}
                        variant={'secondary'}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderExisting = () => {
        if (showModal !== false) {
            return renderExistingDossier()
        }
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => setShowExisting([])}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {kedo.t('Already existing items')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{kedo.t('existing_items_description')}</p>
                    <ul className={'list-group'}>
                        {showExisting.map((existingItem) => (
                            <li
                                key={existingItem.id}
                                className={
                                    'list-group-item list-group-item-action'
                                }
                                onClick={() => {
                                    setShowModal(true)
                                    setExistingUser(existingItem)
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {existingItem.summary
                                    ? existingItem.summary
                                    : existingItem.id}
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    {existingConstraintType === 'warning' ? (
                        <Button
                            onClick={() => {
                                setIgnoreWarning(true)
                                setTimeout(
                                    () => submitDossier(existingDp, false),
                                    50
                                )
                            }}
                        >
                            {kedo.t('Ignore warning and save')}
                        </Button>
                    ) : null}
                    <Button
                        onClick={() => setShowExisting([])}
                        variant={'secondary'}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const findAddress = (displayItem, changedDdiId) => {
        BagHelper.autocomplete(kedo, defdossier, getCurrentRef())
    }

    const onChangeValue = (itemId, value, item) => {
        //BAG: Lookup addresses
        if (
            defdossier &&
            defdossier.settings &&
            defdossier.settings.address_lookup_settings &&
            defdossier.settings.address_lookup_settings.address_lookup === true
        ) {
            if (
                parseInt(
                    defdossier.settings.address_lookup_settings.zipcode_ddi
                ) === itemId
            ) {
                findAddress(item, itemId)
            } else if (
                parseInt(
                    defdossier.settings.address_lookup_settings.housenr_ddi
                ) === itemId
            ) {
                findAddress(item, itemId)
            }
        }

        //TODO KeDo: Check conditions here.
        //TODO KeDo: Check other modules here and maybe change data based on
    }

    const getContentByDisplayPosition = (displayPosition) => {
        const stateContent = content

        if (displayPosition.display_items.length >= 0) {
            displayPosition.display_items.map((displayItem) => {
                content[displayItem.id] = stateContent[displayItem.id]
            })
        }

        return content
    }

    const checkErrors = (newErrors) => {
        const values = []

        DisplayItemHelper.filterViews(items, [
            DisplayItemHelper.EDIT_VIEW,
            DisplayItemHelper.SHOW_EDIT_VIEW,
        ]).map((filterItem) => {
            values.push({
                id: filterItem.id,
                type: filterItem.def_dossier_def_field
                    ? filterItem.def_dossier_def_field.def_field.type
                    : filterItem.def_dossier_link,
                error: newErrors
                    ? newErrors[filterItem.id]
                    : newErrors[filterItem.id],
            })
        })

        const focusItem = values.find((item) => item.error)

        if (focusItem) {
            return focusItem.id
        }
    }

    const checkIsOnlySingleEmbeddedField = (displayPosition): boolean => {
        if (!displayPosition.display_items) {
            return false
        }

        const ddiItems = DisplayItemHelper.filterViews(
            displayPosition.display_items,
            ['edit', 'show', 'show/edit']
        )

        if (
            displayPosition.display_items.length ===
            DisplayItemHelper.filterViews(ddiItems, ['show']).length
        ) {
            return true
        }

        if (ddiItems.length !== 1) {
            return false
        }

        if (!ddiItems[0].def_dossier_link) {
            return false
        }

        return ddiItems[0].def_dossier_link.type_of_link === 'embeddedList'
    }

    const getDisplayPositionContent = (
        displayPosition,
        showEditButton = true,
        alwaysShow = false,
        sidebar = false
    ) => {
        //Check if we have display items
        if (
            !displayPosition ||
            !displayPosition.display_items ||
            displayPosition.display_items.length <= 0
        ) {
            if (
                displayPosition.type === 'main_content' &&
                (kedo.user().isSuperAdmin() || kedo.env().isEnvironmentAdmin())
            ) {
                return (
                    <Alert variant={'warning'}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp; {kedo.t('No items found')}
                    </Alert>
                )
            }
            return ''
        }

        if (fetchPosition === displayPosition.id) {
            return <LoadingDefault />
        }

        const isOnlySingleEmbeddedField =
            checkIsOnlySingleEmbeddedField(displayPosition)

        const roleIds = kedo
            .env()
            .getCurrentEnvironmentRoles()
            .map((item) => item.id)

        return (
            <div>
                {showEditButton &&
                !dossier.archived &&
                !isOnlySingleEmbeddedField
                    ? renderEditButton(displayPosition.id)
                    : null}
                <DefaultGrid
                    changeValue={onChangeValue}
                    defDossierSettings={
                        defdossier && defdossier.settings
                            ? defdossier.settings
                            : null
                    }
                    conditions={
                        defdossier?.settings?.conditions
                            ? defdossier.settings.conditions
                            : []
                    }
                    content={getContentByDisplayPosition(displayPosition)}
                    dossier={dossier}
                    errors={errors}
                    fetch={fetchDossier}
                    embedded={props.embedded}
                    hiddenfields={
                        defdossier?.settings?.hiddenfields
                            ? defdossier.settings.hiddenfields
                            : []
                    }
                    items={DisplayItemHelper.filterViews(
                        displayPosition.display_items,
                        ['edit', 'show/edit', 'show']
                    ).filter((item) => {
                        if (
                            !item.permissions_type ||
                            kedo.isAdminOrEnvironmentAdmin()
                        ) {
                            return true
                        }

                        if (
                            item.permissions_type === 'INCLUDE_SELECTED_ROLES'
                        ) {
                            if (
                                item.roles.filter((roleItem) =>
                                    roleIds.includes(roleItem.role.id)
                                ).length > 0
                            ) {
                                return true
                            } else {
                                return false
                            }
                        } else if (
                            item.permissions_type === 'EXCLUDE_SELECTED_ROLES'
                        ) {
                            if (
                                item.roles.filter((roleItem) =>
                                    roleIds.includes(roleItem.role.id)
                                ).length <= 0
                            ) {
                                return true
                            } else {
                                return false
                            }
                        }

                        return true
                    })}
                    kedo={kedo}
                    mode={
                        alwaysShow
                            ? 'show'
                            : edit === displayPosition.id
                            ? 'edit'
                            : 'show'
                    }
                    ref={
                        parseInt(activeTabKey) === displayPosition.id
                            ? getCurrentRef()
                            : null
                    }
                />
                {showEditButton &&
                !dossier.archived &&
                !isOnlySingleEmbeddedField
                    ? renderEditButton(displayPosition.id)
                    : null}
            </div>
        )
    }

    const isAllowedEditTab = (displayPosition) => {
        if (!defdossier || !defdossier.id) {
            return false
        }
        const ddId = defdossier.id
        return kedo.env().isAllowedDefDossier('edit', ddId, kedo.user())
    }

    const handleOpenEdit = (displayPositionId) => {
        setEdit(displayPositionId)
    }

    const setContentByResponse = (responseContent) => {
        const newContent = {}
        const newDataIds = Object.keys(responseContent)
        newDataIds.map((key) => (newContent[key] = responseContent[key]))

        setContent(newContent)
    }

    const handleCloseEdit = (displayPositionId) => {
        setEdit(false)
        setErrors([])

        getCurrentRef().current.onCancel()
        if (props.onClose) {
            props.onClose()
        }
    }

    const loadIsEditCredAllowed = () => {
        if (isLoadingCustomCreds) {
            return
        }

        const api = kedo.api()
        setIsLoadingCustomCreds(true)

        const params = {
            params: {
                defDossier: defdossier.id,
                credential: 'edit',
                dossier: id,
            },
        }

        api.get(api.getIsCredentialAllowedEndpoint(), params)
            .then((response) => {
                setIsLoadingCustomCreds(false)
                setCustomCredsInitialized(true)
                setIsCustomCredEditAllowed(response.data.allowed)
            })
            .catch(() => {
                setIsLoadingCustomCreds(false)
                setCustomCredsInitialized(true)
            })
    }

    const renderEditButton = (displayPositionId) => {
        if (props.quickEdit) {
            setEdit(displayPositionId)
        }

        if (fetchPosition === displayPositionId) {
            return ''
        }

        if (
            true !==
            kedo.env().isAllowedDefDossier('edit', dossier.def_dossier_id)
        ) {
            return ''
        }

        const hasCustomCreds = kedo
            .env()
            .customCredsDefDossier('edit', dossier.def_dossier_id)
        if (
            hasCustomCreds === true &&
            (isLoadingCustomCreds || !customCredsInitialized)
        ) {
            loadIsEditCredAllowed()
            return <LoadingDefault />
        }

        if (hasCustomCreds && isCustomCredEditAllowed !== true) {
            setEdit(false)
            handlePermissions()
            return ''
        }

        if (hideEditButton && !props.embeddedInlineSingle) {
            return ''
        }

        if (edit !== displayPositionId) {
            return (
                <div>
                    <hr />
                    {props.existingUser &&
                    !props.embeddedInlineSingle ? null : (
                        <Button
                            variant="primary"
                            onClick={() => handleOpenEdit(displayPositionId)}
                            style={{ marginLeft: '0' }}
                        >
                            <FontAwesomeIcon icon={faCog} />
                            &nbsp; {kedo.t('Edit')}
                        </Button>
                    )}

                    {props.showSaveClose === true && props.onClose ? (
                        <Button
                            className={'ml-2'}
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose()
                                }
                                if (props.quickEdit) {
                                    setEdit(null)
                                }
                            }}
                            title={kedo.t('Close')}
                            variant="secondary"
                        >
                            {kedo.t('Close')}
                        </Button>
                    ) : null}
                </div>
            )
        }

        return (
            <div>
                <hr />
                {props.showSaveClose === true ? (
                    <Button
                        className={'mr-2'}
                        disabled={submitting ? true : false}
                        onClick={() => submitDossier(displayPositionId, true)}
                        title={kedo.t('Save and close')}
                        variant="primary"
                    >
                        {submitting && subSaveAndClose === true ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Save and close')}
                    </Button>
                ) : null}
                <Button
                    className={'mr-2'}
                    disabled={submitting}
                    onClick={() => {
                        submitDossier(displayPositionId, false)
                        props.quickEdit ? setEdit(null) : null
                    }}
                    title={kedo.t('Save')}
                    variant="primary"
                >
                    {submitting && !subSaveAndClose ? (
                        <LoadingDefault size={'sm'} as={'span'} />
                    ) : (
                        <FontAwesomeIcon icon={faSave} />
                    )}
                    &nbsp; {kedo.t('Save')}
                </Button>
                <Button
                    disabled={submitting}
                    variant="secondary"
                    title={kedo.t('Cancel')}
                    onClick={() => {
                        handleCloseEdit(displayPositionId)
                        if (props.quickEdit) {
                            setEdit(null)
                        }
                    }}
                >
                    {kedo.t('Cancel')}
                </Button>
            </div>
        )
    }

    const submitDossier = async (
        displayPositionId,
        saveAndClose
    ): Promise<void> => {
        setSubmitting(true)
        setSubSaveAndClose(saveAndClose)

        const currentDisplayPosition = displayPositions.find(function (item) {
            return item.id === displayPositionId
        })

        const ref = getCurrentRef()
        if (!ref.current) {
            setSubmitting(false)
            return
        }

        const values = ref.current.getValues()

        const submitErrors = EntityHelper.checkCanSubmitDisplayPosition(
            kedo,
            defdossier,
            currentDisplayPosition,
            getCurrentRef(),
            values,
            ignoreWarning
        )

        if (Object.keys(submitErrors).length > 0) {
            setErrors(submitErrors)
            setSubmitting(false)
            const errorId = checkErrors(submitErrors)
            getCurrentRef().current.doFocus(errorId)
            return
        }

        //TODO: Let the dossier service handle this
        try {
            const result = await dossierService.updateDossier(
                id,
                displayPositionId,
                values
            )
            setEdit(null)
            setSubmitting(false)
            setShowExisting([])
            setSubSaveAndClose(false)
            setErrors([])
            setExistingDp(null)
            setExistingConstraintType(null)

            if (props.onSuccess) {
                props.onSuccess(saveAndClose)
            }
            //Reload dossier (update summary and fields)
            fetchDossier(displayPositionId)
            toast.success(kedo.t('dossier_errors.success_edit'), toastOptions)
        } catch (error) {
            setSubmitting(false)
            setShowExisting([])
            setSubSaveAndClose(false)

            if (error.response && error.response.status === 409) {
                setErrors(error.response.data.errors)
                if (
                    error.response.data.existing &&
                    error.response.data.existing.length > 0
                ) {
                    setExistingDp(displayPositionId)
                    setShowExisting(error.response.data.existing)
                    setExistingConstraintType(
                        error.response.data.constraint_type
                    )
                }
            } else if (error.response && error.response.status === 403) {
                toast.error(
                    kedo.t('dossier_errors.permission_edit'),
                    toastOptions
                )
            } else {
                toast.error(kedo.t('dossier_errors.error_edit'), toastOptions)

                setErrors(error.response.data.errors)
                setExistingDp(null)
                setExistingConstraintType(null)
                const errorId = checkErrors([])
                getCurrentRef().current.doFocus(errorId)
            }
        }
    }

    useEffect(() => {
        if (props.quickEdit || props.quickShow) {
            return
        }

        const searchParams = new URL(window.location.href).searchParams
        const selectedTab = props.selectedTab ?? searchParams.get('selectedTab')

        if (!selectedTab) {
            return
        }

        if (!props.embeddedInlineSingle) {
            handleTabSelect(selectedTab)
        }
    }, [props.selectedTab, props.quickEdit, displayPositions])

    useEffect(() => {
        const initializeDossier = async () => {
            try {
                await fetchDossier((mainPos: { id: any }) => {
                    const searchParams = new URL(window.location.href)
                        .searchParams
                    const selectedTab =
                        props.selectedTab ?? searchParams.get('selectedTab')
                    if (selectedTab) {
                        setActiveTabKey(selectedTab)
                    } else {
                        setActiveTabKey(
                            mainPos && mainPos.id ? mainPos.id : null
                        )
                    }
                })

                loadClientSocket()
            } catch (error) {
                console.error(kedo.t('Error loading dossier data:'), error)
            }
        }

        initializeDossier()

        return () => {
            try {
                socketService.dossier.exit()
                if (props.embedded) {
                    socketService.dossier.join(parseInt(props.embedded))
                } else {
                    socketService.dossier.removeDosListeners()
                }
            } catch (error) {
                console.error(kedo.t('Error cleaning up socket:'), error)
            }
        }
    }, [])

    useEffect(() => {
        if (dossier && props.setTitle) {
            props.setTitle(renderModalHeader)
        }

        if (dossier) {
            kedo.title().set(
                `${dossier.summary} | ${kedo
                    .env()
                    .translateItem(defdossier, 'defdossier', true)}`
            )
        }
    }, [dossier, activeTabKey])

    useEffect(() => {
        return () => {
            kedo.title().revert()
        }
    }, [])

    useEffect(() => {
        const handleSocketConnection = () => {
            try {
                socketService.dossier.exit()
                if (props.embedded) {
                    socketService.dossier.join(parseInt(props.embedded))
                } else {
                    socketService.dossier.removeDosListeners()
                }
            } catch (error) {
                console.error(
                    kedo.t('Error managing socket connection:'),
                    error
                )
                toast.error(kedo.t('Error managing socket connection.'))
            }
        }

        handleSocketConnection()

        fetchDossier()
    }, [id])

    const getGeneralHeader = () => {
        return (
            <DefaultHeader
                dossier={dossier}
                defdossier={defdossier}
                onDeleteSuccess={props.onDeleteSuccess}
                showBreadcrumb={!props.dossierId}
                favorite={favorite}
                subscribed={subscribed}
            />
        )
    }

    // const fetchSidebar = async (positionId) => {
    //     const dossierData = await dossierService.getDossier(id, positionId)

    //     setSidebarContent(dossierData.content)
    // }

    const getModalHeader = () => {
        if (!dossier) {
            return null
        }

        return (
            <Modal.Title className={'w-100'}>
                <h3>
                    {dossier.summary}{' '}
                    {props && props.modalized === true ? (
                        <div className={'float-right mr-2'}>
                            <ContentDossierToolbar
                                modalized={true}
                                onDeleteSuccess={props.onDeleteSuccess}
                                favorite={favorite}
                                subscribed={subscribed}
                                kedo={kedo}
                                id={id}
                                defdossier={defdossier}
                                dossier={dossier}
                                activeTabKey={activeTabKey}
                                embedded={props.embedded}
                            />
                        </div>
                    ) : null}
                </h3>
            </Modal.Title>
        )
    }

    if (pageError === '401') {
        throw new Error(kedo.t('403_general_message'))
    }
    if (pageError === '403') {
        throw new Error(kedo.t('403_general_message'))
    }
    if (pageError === '404') {
        throw new Error(kedo.t('404_title'))
    }

    if (loading || displayPositions.length <= 0) {
        return <LoadingPage kedo={kedo} />
    }

    let summary = dossier.summary
    if (dossier.summary && dossier.summary.length > 75) {
        summary = dossier.summary.substring(0, 75) + '...'
    }

    let pageLayout = 'default'
    if (
        defdossier &&
        defdossier.settings &&
        defdossier.settings.detail_layout
    ) {
        pageLayout = defdossier.settings.detail_layout
    }

    const extraProps = {
        defdossier: defdossier,
        subscribed: subscribed,
        favorite: favorite,
        loadModule: loadModule,
        content: content,
        dossier: dossier,
        items: items,
        id: id,
        sidebarContent: sidebarContent,
        sidebarDisplayItems: sidebarDisplayItems,
        displayPositions: displayPositions,
        showExisting: showExisting,
        isAllowedEditTab: isAllowedEditTab,
        renderExisting: renderExisting,
        getDisplayPositionContent: getDisplayPositionContent,
        getGeneralHeader: getGeneralHeader,
        fetchTabContent: fetchTabContent,
        handleTabSelect: handleTabSelect,
        embeddedInlineSingle: props.embeddedInlineSingle ?? false,
        activeTabKey: activeTabKey,
        socketUsers: socketUsers,
    }

    switch (pageLayout) {
        case 'vertical':
            return (
                <VerticalPageTemplate {...extraProps} {...props} kedo={kedo} />
            )
        default:
            return (
                <DefaultPageTemplate {...extraProps} {...props} kedo={kedo} />
            )
    }
}

export default DetailContentDossier
