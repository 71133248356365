import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Form, Button, Alert } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import TwoFactorAuthentication from './TwoFactorAuthentication'
import Api from '../../Api'
import { KeDoContext } from '../../kedo-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputGroup from 'react-bootstrap/InputGroup'
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash'
import { faLock } from '@fortawesome/pro-duotone-svg-icons/faLock'
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser'
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye'
import azureLoginImage from '../../static/ms-symbollockup_signin_light.svg'
import googleLoginImage from '../../static/btn_google_signin_dark_normal_web@2x.png'

function isMoreThan24HoursAgo(date) {
    const previousDate = new Date(date)
    const currentDate = new Date()

    const differenceInMillis = currentDate.getTime() - previousDate.getTime()
    const differenceInHours = differenceInMillis / (1000 * 60 * 60)
    return differenceInHours > 24
}

class Login extends Component {
    constructor(props) {
        super(props)

        //Redirect to url provided
        if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.from &&
            this.props.location.state.from.pathname &&
            this.props.location.state.from.pathname !== '/login'
        ) {
            let lastVisit = localStorage.getItem('lastVisit')

            if (lastVisit && isMoreThan24HoursAgo(parseInt(lastVisit))) {
                localStorage.setItem('referer', '/')
            } else {
                localStorage.setItem(
                    'referer',
                    this.props.location.state.from.pathname
                )
            }
        }

        this.state = {
            api: this.props.api,
            loading: false,
            loginErrors: false,
            password: '',
            title: this.props.title ? this.props.title : false,
            username: '',
            showPassword: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onLoginEnterKey = this.onLoginEnterKey.bind(this)
        this.connectAzure = this.connectAzure.bind(this)
    }
    static contextType = KeDoContext

    onLoginEnterKey(event) {
        if (
            this.state.username &&
            this.state.password &&
            event.key === 'Enter'
        ) {
            this.handleSubmit(event)
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            loginErrors: false,
        })

        if (event.key === 'Enter') {
            this.handleSubmit(event)
        }
    }

    connectGoogle() {
        window.location = this.props.kedo.api().getGoogleConnect()
    }

    connectAzure() {
        window.location = this.props.kedo.api().getAzureConnect()
    }

    handleSubmit(event) {
        const { username, password } = this.state
        this.setState({ loading: true })
        let api = new Api()
        api.postRaw('/login_check', { username: username, password: password })
            .then((response) => {
                if (response.data) {
                    this.props.handleSuccessfulAuth(response.data)
                }
                this.setState({ loading: false })
            })
            .catch((error) => {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 401 &&
                    error.response.data.errors &&
                    error.response.data.errors.json === true
                ) {
                    this.setState({
                        loading: false,
                        showOtp: true,
                    })
                } else {
                    this.setState({
                        loading: false,
                        loginErrors: true,
                    })
                }
            })

        event.preventDefault()
    }

    render() {
        const kedo = this.context

        if (this.state.loading) {
            return <LoadingDefault size={'6x'} />
        }

        if (this.state.showOtp === true) {
            return (
                <TwoFactorAuthentication
                    kedo={kedo}
                    username={this.state.username}
                    password={this.state.password}
                    onSuccess={this.props.handleSuccessfulAuth}
                />
            )
        }
        //const kedo = this.props.kedo;
        return (
            <Form onSubmit={this.handleSubmit}>
                {this.state.title ? <h1>{this.state.title}</h1> : null}
                <hr className={'login-hr'} />
                {this.state.loginErrors ? (
                    <Alert variant={'danger'}>
                        {kedo.t('Incorrect username or password')}
                    </Alert>
                ) : null}
                <InputGroup>
                    <span className="input-group-prepend">
                        <span className="input-group-text bg-transparent">
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                        </span>
                    </span>
                    <Form.Control
                        type="text"
                        autoFocus
                        name="username"
                        autoComplete="off"
                        className="form-control"
                        placeholder={kedo.t('Username')}
                        value={this.state.username}
                        onChange={this.handleChange}
                        onKeyPress={this.onLoginEnterKey}
                        required
                    />
                </InputGroup>
                <br />

                <InputGroup>
                    <span className="input-group-prepend">
                        <span className="input-group-text bg-transparent">
                            <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                        </span>
                    </span>
                    <Form.Control
                        type={this.state.showPassword ? 'text' : 'password'}
                        name="password"
                        className="form-control border-right-0"
                        autoComplete="off"
                        placeholder={kedo.t('Password')}
                        value={this.state.password}
                        onChange={this.handleChange}
                        onKeyPress={this.onLoginEnterKey}
                        required
                    />
                    <span className="input-group-append ">
                        <Button
                            className="bg-transparent rounded-right border border-left-0 shadow-none"
                            onClick={() =>
                                this.setState({
                                    showPassword: !this.state.showPassword,
                                })
                            }
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.state.showPassword ? faEyeSlash : faEye
                                }
                            ></FontAwesomeIcon>
                        </Button>
                    </span>
                </InputGroup>
                <Link className={'float-right'} to={'/forgot_password'}>
                    <small>{kedo.t('Forgot password')}</small>
                </Link>
                <br />
                <br />

                <Button
                    block
                    variant={'primary'}
                    onClick={this.handleSubmit}
                    disabled={!this.state.username || !this.state.password}
                    title={
                        !this.state.username || !this.state.password
                            ? 'Enter a username and password to continue'
                            : null
                    }
                >
                    {kedo.t('Continue')}
                </Button>

                <div className="d-flex align-items-center pt-2 ">
                    <hr className="border border-dark w-25" />
                    {kedo.t('OR')}
                    <hr className="border border-dark w-25" />
                </div>

                {/* <hr className='border border-dark w-25' /> */}
                {/* <Button */}
                {/*    variant={'outline-light'}*/}
                {/*    onClick={this.connectGoogle}*/}
                {/*    title={kedo.t('Login with Google')} */}
                {/*>*/}
                {/*    <img style={{ maxWidth: '212px' }} src={googleLoginImage} />*/}
                {/*</Button>*/}

                <Button
                    variant={'transparant'}
                    onClick={this.connectAzure}
                    title={kedo.t('Login with Azure')}
                    className="shadow-none"
                >
                    <img src={azureLoginImage} />
                </Button>
            </Form>
        )
    }
}
export default withRouter(Login)
