import React from 'react'
import { Row, Col, Form, Badge } from 'react-bootstrap'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import * as Datetime from 'react-datetime'
import moment from 'moment'
import 'moment-timezone'
import InputMask from 'react-input-mask'
import MultiRangeSlider from './DateRangeSlider'
import SoloRangeSlider from './SoloRangeSlider'

class DateTimeField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        let currentDefault = this.getDefFieldSetting(
            this.props.item,
            'current_time_as_default',
            false
        )

        if (this.props.item?.settings?.default_current_date !== undefined) {
            currentDefault = this.props.item.settings.default_current_date
        }

        this.state = {
            value: this.props.value
                ? this.props.value
                : currentDefault &&
                  this.props.mode !== 'search' &&
                  this.props.mode !== 'advanced-search'
                ? new Date()
                : null,
            show_slider:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_slider === true
                    ? true
                    : false,
            deadline:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.deadline
                    ? this.props.item.settings.deadline
                    : null,
            searchType:
                (this.props.mode === 'search' ||
                    this.props.mode === 'advanced-search') &&
                this.props.value &&
                this.props.value.type
                    ? this.props.value.type
                    : 'on',
            date1:
                (this.props.mode === 'search' ||
                    this.props.mode === 'advanced-search') &&
                this.props.value &&
                this.props.value.date1
                    ? this.props.value.date1
                    : null,
            date2:
                (this.props.mode === 'search' ||
                    this.props.mode === 'advanced-search') &&
                this.props.value &&
                this.props.value.date2
                    ? this.props.value.date2
                    : null,
            current_time_as_default: currentDefault,
            restriction: this.getDefFieldSetting(
                this.props.item,
                'restriction',
                null
            ),
            withtime: this.getDefFieldSetting(
                this.props.item,
                'withtime',
                false
            ),
        }

        this.doFocus = this.doFocus.bind(this)
        this.onChangeSearch = this.onChangeSearch.bind(this)
        this.onChangeDate = this.onChangeDate.bind(this)
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getDefFieldSetting(item, name, defaultVal) {
        if (
            !item ||
            !item.def_dossier_def_field ||
            !item.def_dossier_def_field.def_field ||
            !item.def_dossier_def_field.def_field.settings
        ) {
            return defaultVal
        }

        if (!item.def_dossier_def_field.def_field.settings[name]) {
            return defaultVal
        }

        return item.def_dossier_def_field.def_field.settings[name]
    }

    getValue() {
        if (this.state.value && this.state.value.format) {
            if (this.state.withtime !== true) {
                return this.state.value
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .format()
            }

            return this.state.value.format()
        }

        return this.state.value
    }

    onChangeSearch(event, searchType) {
        let searchValue = {
            custom: 'date',
            type: this.state.searchType,
            date1: this.state.date1,
            date2: this.state.date2,
        }

        if (event) {
            searchValue[event.target.name] = event.target.value
            this.setState({ [event.target.name]: event.target.value })
        } else {
            searchValue.type = searchType
            this.setState({ searchType: searchType })
        }

        if (
            this.props.item.settings &&
            this.props.item.settings.dossier_data &&
            (this.props.item.settings.dossier_data === 'created_at' ||
                this.props.item.settings.dossier_data === 'modified_at')
        ) {
            this.props.changeValue(
                this.props.item.settings.dossier_data,
                searchValue
            )
        } else if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, searchValue)
        }
    }

    onChange(event) {
        this.setState({ value: event.target.value })
        if (this.props.changeValue) {
            this.props.changeValue(
                this.props.item.id,
                event.target.value,
                this.props.item
            )
        }
    }

    getFormattedDate(value) {
        if (value.length <= 0) {
            return null
        }

        let date = moment(value)
        let dateTimeFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'medium',
            timeZone: 'Europe/Amsterdam',
        })
        let dateFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeZone: 'Europe/Amsterdam',
        })

        if (this.state.withtime) {
            return dateTimeFormat.format(date)
        }
        date = date.hours(0).minutes(0).seconds(0).milliseconds(0)
        try {
            date = dateFormat.format(date)
        } catch (errr) {
            return null
        }

        return date
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    getSearchTypeTitle(searchType) {
        if (!searchType || searchType === 'on') {
            return this.props.kedo.t('time_on')
        }

        if (searchType === 'after') {
            return this.props.kedo.t('time_after')
        }

        if (searchType === 'before') {
            return this.props.kedo.t('time_before')
        }

        if (searchType === 'between') {
            return this.props.kedo.t('time_from_till')
        }

        return searchType.charAt(0).toUpperCase() + searchType.slice(1)
    }

    getCurrentLocale() {
        let locale = this.props.kedo.env().getSelectedLocale()
        if (locale && locale.code) {
            return locale.code.replace('_', '-')
        }

        return 'en'
    }

    renderSearch() {
        if (this.state.show_slider === true) {
            return (
                <Row>
                    <Col className={'d-flex align-content-stretch'}>
                        <DropdownButton
                            as={'ButtonGroup'}
                            variant="outline-secondary"
                            title={this.getSearchTypeTitle(
                                this.state.searchType
                            )}
                        >
                            <Dropdown.Item
                                onClick={() => {
                                    this.setState({ searchType: 'on' })
                                    this.onChangeDate(null, 'on')
                                }}
                                href="#"
                            >
                                {this.props.kedo.t('time_on')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    this.setState({ searchType: 'after' })
                                    this.onChangeDate(null, 'after')
                                }}
                                href="#"
                            >
                                {this.props.kedo.t('time_after')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    this.setState({ searchType: 'before' })
                                    this.onChangeDate(null, 'before')
                                }}
                                href="#"
                            >
                                {this.props.kedo.t('time_before')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    this.setState({ searchType: 'between' })
                                    this.onChangeDate(null, 'between')
                                }}
                                href="#"
                            >
                                {this.props.kedo.t('time_from_till')}
                            </Dropdown.Item>
                        </DropdownButton>
                        &nbsp;&nbsp;
                        {this.state.searchType !== 'between' ? (
                            <SoloRangeSlider
                                min={18}
                                max={120}
                                type={this.state.searchType}
                                onChange={(min, max) => {
                                    //momentjs get current year and minus max
                                    let tillDate = moment().subtract(
                                        min,
                                        'years'
                                    )
                                    let fromDate = moment().subtract(
                                        max,
                                        'years'
                                    )
                                    this.onChangeDate(tillDate, 'date1')
                                    this.onChangeDate(fromDate, 'date2')
                                }}
                            />
                        ) : null}
                        {this.state.searchType === 'between' ? (
                            <Row>
                                <Col className={'d-flex align-content-stretch'}>
                                    <MultiRangeSlider
                                        min={18}
                                        max={120}
                                        onChange={(min, max) => {
                                            this.setState({
                                                searchType: 'between',
                                            })
                                            //momentjs get current year and minus max
                                            let tillDate = moment().subtract(
                                                min,
                                                'years'
                                            )
                                            let fromDate = moment().subtract(
                                                max,
                                                'years'
                                            )
                                            this.onChangeDate(fromDate, 'date1')
                                            this.onChangeDate(tillDate, 'date2')
                                        }}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                    </Col>
                    {this.isInvalid() ? (
                        <span className="fake-feedback">
                            {this.props.errors}
                        </span>
                    ) : null}
                </Row>
            )
        }

        return (
            <Row>
                <Col className={'d-flex align-content-stretch'}>
                    <DropdownButton
                        as={'ButtonGroup'}
                        variant="outline-secondary"
                        title={this.getSearchTypeTitle(this.state.searchType)}
                    >
                        <Dropdown.Item
                            onClick={() => {
                                this.setState({ searchType: 'on' })
                                this.onChangeDate(null, 'on')
                            }}
                            href="#"
                        >
                            {this.props.kedo.t('time_on')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                this.setState({ searchType: 'after' })
                                this.onChangeDate(null, 'after')
                            }}
                            href="#"
                        >
                            {this.props.kedo.t('time_after')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                this.setState({ searchType: 'before' })
                                this.onChangeDate(null, 'before')
                            }}
                            href="#"
                        >
                            {this.props.kedo.t('time_before')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                this.setState({ searchType: 'between' })
                                this.onChangeDate(null, 'between')
                            }}
                            href="#"
                        >
                            {this.props.kedo.t('time_from_till')}
                        </Dropdown.Item>
                    </DropdownButton>
                    &nbsp;&nbsp;
                    {this.state.searchType !== 'between'
                        ? this.getSearchDateTime('date1', this.state.date1)
                        : null}
                    {this.state.searchType === 'between' ? (
                        <Row>
                            <Col xs={6} style={{ paddingRight: '3px' }}>
                                {this.getSearchDateTime(
                                    'date1',
                                    this.state.date1
                                )}
                            </Col>
                            <Col xs={6} style={{ paddingLeft: '3px' }}>
                                {this.getSearchDateTime(
                                    'date2',
                                    this.state.date2
                                )}
                            </Col>
                        </Row>
                    ) : null}
                </Col>
                {this.isInvalid() ? (
                    <span className="fake-feedback">{this.props.errors}</span>
                ) : null}
            </Row>
        )
    }

    onChangeDate(moment, name, searchType) {
        if (this.props.changeValue && moment && moment.length <= 0) {
            this.props.changeValue(this.props.item.id, [])
        }

        if (!moment || !moment.format) {
            return
        }

        let searchValue = {
            custom: 'date',
            type: this.state.searchType,
            date1: this.state.date1,
            date2: this.state.date2,
        }

        if (moment) {
            searchValue[name] = moment.format()
            this.setState({ [name]: moment.format() })
        } else {
            searchValue.type = searchType
            this.setState({ searchType: searchType })
        }

        if (
            this.props.item.settings &&
            this.props.item.settings.dossier_data &&
            (this.props.item.settings.dossier_data === 'created_at' ||
                this.props.item.settings.dossier_data === 'modified_at')
        ) {
            this.props.changeValue(
                this.props.item.settings.dossier_data,
                searchValue
            )
        } else if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, searchValue)
        }
    }

    getSearchDateTime(name, value) {
        var valid = function () {
            return true
        }

        return (
            <Datetime
                value={moment(value)}
                closeOnSelect
                locale={this.getCurrentLocale()}
                isValidDate={valid}
                onChange={(moment) => this.onChangeDate(moment, name)}
                ref={this.innerRef}
                timeFormat={this.state.withtime}
                renderInput={(props) => (
                    <InputMask
                        mask={
                            this.state.withtime
                                ? '99-99-9999 99:99'
                                : '99-99-9999'
                        }
                        maskChar=""
                        {...props}
                    >
                        {() => (
                            <Form.Control
                                className={
                                    this.isInvalid() ? 'fake-feedback' : ''
                                }
                            />
                        )}
                    </InputMask>
                )}
            />
        )
    }

    getColor(backgroundColor, text, fontSize = '90%') {
        return (
            <span>
                <Badge
                    pill
                    style={{
                        backgroundColor: backgroundColor,
                        color: '#fff',
                        fontSize: fontSize
                    }}
                >
                    {text}
                </Badge>
            </span>
        )
    }

    renderShow() {
        if (!this.state.value) {
            return null
        }

        if (this.state.value.length <= 0) {
            return null
        }

        if (
            this.props.item.settings &&
            this.props.item.settings.show_ago === true
        ) {
            moment.locale(this.props.kedo.utils().getI18N().language)

            return moment().diff(moment(this.state.value), 'years')
        }
        
        
        if (this.state.deadline != null) {
            const date = this.getFormattedDate(this.state.value);
            const targetDate = new Date(date);
            const currentDate = new Date();
            const timeDifference = targetDate - currentDate;
            const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
            const isLeapYear = year => new Date(year, 1, 29).getMonth() === 1;
        
            for (const items of this.state.deadline) {
                const { number, type, color, when } = items;
                let daysThreshold = 0;
                const year = new Date(this.getFormattedDate(this.state.value)).getFullYear();
                const leapYear = isLeapYear(year);
        
                if (type === 'day') {
                    daysThreshold = number;
                } else if(type === 'week') {
                    daysThreshold = number * 7;
                } else if(type === 'year') {
                    daysThreshold = number * (leapYear ? 366 : 365);
                }
        
                if (daysDifference <= daysThreshold && daysDifference >= 0 && when === 'from now') {
                    return color ? this.getColor(color, date) : date;
                } else if (daysDifference < 0 && Math.abs(daysDifference) >= daysThreshold && when === 'after') {
                    return color ? this.getColor(color, date) : date;
                }
            }
        
            return date;
        } else {
            return this.getFormattedDate(this.state.value)
        }
    }

    renderEdit() {
        let valid = function () {
            return true
        }
        if (this.state.restriction) {
            let today = Datetime.moment()
            if (this.state.restriction === 'before_and_eq_current_date') {
                valid = function (current) {
                    return current.isSameOrBefore(today)
                }
            } else if (this.state.restriction === 'after_and_eq_current_date') {
                valid = function (current) {
                    return current.isSameOrAfter(today)
                }
            }
        }

        let date = this.state.value
            ? moment(this.state.value)
            : this.state.current_time_as_default === true
            ? new Date()
            : ''

        return (
            <div ref={this.innerRef}>
                <Datetime
                    defaultValue={date}
                    locale={this.getCurrentLocale()}
                    isValidDate={valid}
                    utc={false}
                    timeFormat={this.state.withtime}
                    closeOnSelect
                    onChange={(moment) => {
                        let value = ''
                        if (moment && moment.format) {
                            value = moment.toISOString()
                        }
                        this.setState({ value: value })
                        if (this.props.changeValue) {
                            this.props.changeValue(this.props.item.id, value)
                        }
                    }}
                    renderInput={(props) => (
                        <InputMask
                            mask={
                                this.state.withtime
                                    ? '99-99-9999 99:99'
                                    : '99-99-9999'
                            }
                            maskChar=""
                            {...props}
                        >
                            {() => (
                                <Form.Control
                                    className={
                                        this.isInvalid() ? 'fake-feedback' : ''
                                    }
                                />
                            )}
                        </InputMask>
                    )}
                />

                {this.isInvalid() ? (
                    <span className="fake-feedback">{this.props.errors}</span>
                ) : null}
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            this.state.searchType !== nextState.searchType ||
            this.state.date1 !== nextState.date1 ||
            this.state.date2 !== nextState.date2 ||
            this.state.value !== nextState.value
        ) {
            return true
        }
        if (
            nextProps.mode !== this.props.mode ||
            nextProps.errors !== this.props.errors
        ) {
            return true
        }

        return false
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== prevProps.value && this.props.mode === 'show') {
            this.setState({ value: this.props.value })
        }
    }

    render() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return this.renderSearch()
        } else if (
            this.props.mode === 'edit' ||
            this.props.mode === 'show/edit'
        ) {
            return this.renderEdit()
        } else if (this.props.mode === 'list') {
            return this.renderShow()
        }
        return this.renderShow()
    }
}

export default DateTimeField
