import React from 'react'
import TextField from './TextField'
import TextareaField from './TextareaField'
import AmountField from './AmountField'
import AutoIncrementField from './AutoIncrementField'
import DateTimeField from './DateTimeField'
import EmailField from './EmailField'
import LinkField from './LinkField'
import ListField from './ListField'
import TimeField from './TimeField'
import UrlField from './UrlField'
import UserField from './UserField'
import FileField from './FileField'
import { Alert } from 'react-bootstrap'
// import EmbeddedLinkField from './EmbeddedLinkField'
import EmbeddedLinkField from '../../feature/DisplayItems/EmbeddedLinkField.tsx'
import CustomActionButtonField from './CustomActionButtonField'
import GeolocationField from './GeolocationField'
import ImageCodeField from './ImageCodeField'
import DisplayItemBlock from './DisplayItemBlock'
import DisplayItemDossierData from './DisplayItemDossierData'
import CompanySearchField from './CompanySearchField.tsx'
import CalcField from './CalcField'
import { toast } from 'react-toastify'

class DisplayItem extends React.Component {
    constructor(props) {
        super(props)
        this.ddiRef = React.createRef()
    }

    getField(item, mode) {
        let type = ''

        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.def_field
        ) {
            type = item.def_dossier_def_field.def_field.type
        } else if (item.def_dossier_link) {
            type = 'link'
        }

        if (
            type !== 'customActionButton' &&
            this.props.content === '' &&
            mode === 'show'
        ) {
            return (
                <small>
                    <i style={{ color: 'darkgrey' }}>&nbsp;</i>
                </small>
            )
        }

        let compProps = {
            changeValue: this.props.changeValue,
            conditions: this.props.conditions,
            errors: this.props.errors,
            item: item,
            embedded: this.props.embedded,
            linkId: this.props.linkId,
            kedo: this.props.kedo,
            isNewDossier: !this.props.dossier,
            mode: mode,
            custom_layout: this.props.custom_layout,
            onKeyPress: this.props.onKeyPress,
            recheckConditions: this.props.recheckConditions,
            ref: this.ddiRef,
            shoudListenForConditions: this.props.shoudListenForConditions,
            type: type,
            value: this.props.content,
        }

        if (this.props.errors && this.props.errors.length > 0) {
            if (!toast.isActive('error-toast')) {
                toast.error(
                    this.props.kedo.t(
                        'Not all fields are (correctly) filled in.'
                    ),
                    {
                        toastId: 'error-toast',
                        position: 'bottom-center',
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                )
            }
        }

        switch (type) {
            case 'text':
                if (
                    compProps?.item?.settings?.company_lookup &&
                    this.props.mode == 'edit'
                ) {
                    return <CompanySearchField {...compProps} />
                }
                return <TextField {...compProps} />
            case 'textarea':
                return <TextareaField {...compProps} />
            case 'amount':
                return <AmountField {...compProps} />
            case 'autoIncrement':
                return <AutoIncrementField {...compProps} />
            case 'customActionButton':
                return (
                    <CustomActionButtonField
                        onSuccess={(callBck) => {
                            if (this.props.fetchItems) {
                                this.props.fetchItems()
                            }
                            if (callBck) {
                                callBck()
                            }
                        }}
                        dossier={this.props.dossier}
                        {...compProps}
                    />
                )
            case 'date':
                return <DateTimeField {...compProps} />
            case 'email':
                return <EmailField {...compProps} />
            case 'calc':
                return <CalcField {...compProps} />
            case 'link':
                //Check if the user is allowed to show items on the DefDossier
                if (
                    !this.props.kedo
                        .env()
                        .isAllowedDefDossier(
                            'show',
                            item.def_dossier_link.child_def_dossier_id,
                            this.props.kedo.user()
                        )
                ) {
                    return (
                        <Alert variant={'info'}>
                            {this.props.kedo.t('403_general_message')}
                        </Alert>
                    )
                }
                if (
                    !item.def_dossier_link &&
                    !item.def_dossier_def_field &&
                    item.settings &&
                    item.settings.block
                ) {
                    return (
                        <DisplayItemBlock
                            kedo={this.props.kedo}
                            settings={item.settings}
                            dossier={this.props.dossier}
                            id={item.settings.block}
                        />
                    )
                } else if (
                    item.def_dossier_link &&
                    item.def_dossier_link.type_of_link === 'embeddedList' &&
                    mode !== 'search'
                ) {
                    return (
                        <EmbeddedLinkField
                            dossier={this.props.dossier}
                            {...compProps}
                        />
                    )
                }
                return <LinkField dossier={this.props.dossier} {...compProps} />
            case 'list':
                return <ListField dossier={this.props.dossier} {...compProps} />
            case 'time':
                return <TimeField {...compProps} />
            case 'url':
                return <UrlField {...compProps} />
            case 'user':
                return <UserField {...compProps} />
            case 'file':
                return <FileField dossier={this.props.dossier} {...compProps} />
            case 'imageCode':
                return <ImageCodeField {...compProps} />
            case 'geolocation':
                return <GeolocationField {...compProps} />
            default:
                if (
                    !item.def_dossier_link &&
                    !item.def_dossier_def_field &&
                    item.settings &&
                    item.settings.dossier_data
                ) {
                    return (
                        <DisplayItemDossierData
                            item={item}
                            ref={this.ddiRef}
                            mode={mode}
                            changeValue={this.props.changeValue}
                            kedo={this.props.kedo}
                            dossier={this.props.dossier}
                        />
                    )
                } else if (
                    !item.def_dossier_link &&
                    !item.def_dossier_def_field &&
                    item.settings &&
                    item.settings.block
                ) {
                    return (
                        <DisplayItemBlock
                            kedo={this.props.kedo}
                            settings={item.settings}
                            dossier={this.props.dossier}
                            id={item.settings.block}
                        />
                    )
                }

                return (
                    <Alert variant={'danger'}>
                        {this.props.kedo.t('Unknown field type')}
                    </Alert>
                )
        }
    }

    render() {
        if (
            this.props.mode === 'list' &&
            this.props.item.def_dossier_def_field &&
            this.props.item.def_dossier_def_field.def_field &&
            this.props.item.def_dossier_def_field.def_field.type ===
                'customActionButton'
        ) {
            return this.getField(this.props.item, this.props.mode)
        }

        return (
            <div className={'displayItem'}>
                {this.getField(this.props.item, this.props.mode)}
            </div>
        )
    }
}

export default DisplayItem
