import * as React from 'react'
import { RefObject, useRef, useState } from 'react'
import { useEntityService } from '../../../context/EntityServiceContext'

import { Alert, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    faBell,
    faExclamationCircle,
    faSort,
    faSortDown,
    faSortUp,
    faTimes,
    faSave,
} from '@fortawesome/pro-duotone-svg-icons'

import AdminQuickEditMenu from '../../../Components/Elements/AdminQuickEditMenu.jsx'
import DisplayItemHelper from '../../../util/DisplayItemHelper'
import ActionButtons from './Components/ActionButtons'
import DefaultGrid from '../../../Components/Grid/DefaultGrid.jsx'
import Loading from '../../../Components/Elements/Loading/LoadingData.jsx'
import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import EntityHelper from '../../../util/EntityHelper'
interface ContentDossierIndexHeaderProps {
    props: any
    kedo: any
    pager: any
    sort: any
    limit: any
    clearSort: any
    applySort: any
    handleEntriesChange: any
    mode: string
    loadingContent: boolean
    onDeleteSuccess: any
    getSearchFilter: any
    dossiers: Array<any>
    subscriptions: Array<any>
    favorites: Array<any>
    listItems: Array<IDisplayField>
    actionBtns: Array<any>
    fetchDossierResults: any
    selected: Array<any>
    defDossierId: number
    showArchived: boolean
    setSelected: any
    contents: any
    rowInput: any
    refreshResults: any
}

const ContentDossierIndexHeader: React.FC<ContentDossierIndexHeaderProps> = ({
    props,
    kedo,
    pager,
    loadingContent,
    sort,
    limit,
    clearSort,
    applySort,
    handleEntriesChange,
    mode,
    onDeleteSuccess,
    getSearchFilter,
    subscriptions,
    favorites,
    dossiers,
    listItems,
    actionBtns,
    fetchDossierResults,
    refreshResults,
    selected,
    rowInput,
    defDossierId,
    showArchived,
    setSelected,
    contents,
}) => {
    if (!listItems) {
        return
    }

    const [showMergeModal, setShowMergeModal] = useState(false)
    const [rowInputInitialized, setRowInputInitialized] = useState(false)
    const [rowInputContent, setRowInputContent] = useState({})
    const [rowInputErrors, setRowInputErrors] = useState({})
    const [rowInputDd, setRowInputDd] = useState(null)
    const [rowInputValues, setRowInputValues] = useState([])
    const [rowInputItems, setRowInputItems] = useState([])
    const [loadingRowInput, setLoadingRowInput] = useState(false)
    const [rowInputSubmitting, setRowInputSubmitting] = useState(false)
    let curRef = useRef(null)
    const entityService = useEntityService()

    const getCurrentRef = (): RefObject<any> => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    const checkAll = (event) => {
        if (!dossiers) {
            return
        }

        if (true === event.target.checked) {
            setSelected(dossiers.map((item) => item.id))
        } else {
            setSelected([])
        }
    }

    const isSortable = (listField): boolean => {
        if (
            listField &&
            listField.settings &&
            listField.settings.dossier_data &&
            (listField.settings.dossier_data === 'created_at' ||
                listField.settings.dossier_data === 'modified_at')
        ) {
            return true
        }
        if (
            listField.def_dossier_link &&
            listField.def_dossier_link?.settings?.multiple !== true
        ) {
            return true
        }

        if (!listField) {
            return false
        }

        if (!listField.def_dossier_def_field) {
            return false
        }

        if (
            DisplayItemHelper.isTypeOfField(
                listField,
                DisplayItemHelper.FIELD_TEXTAREA_TYPE
            )
        ) {
            return false
        }

        return true
    }

    const isAllowedDd = (credential: string): boolean => {
        return kedo
            .env()
            .isAllowedDefDossier(credential, defDossierId, kedo.user())
    }

    const selectedDossiers = dossiers.filter((item) =>
        selected.find((selectedId) => selectedId === item.id)
    )
    const archivedDossiers =
        showArchived && selectedDossiers.find((item) => item.archived === true)
    const dearchivedDossiers =
        showArchived && selectedDossiers.find((item) => item.archived === false)

    let extraColumns = 3
    if (actionBtns.length > 0) {
        extraColumns = 4
    }

    const hasSubscriptions = subscriptions && subscriptions.length > 0

    if (loadingContent) {
        return
    }

    const checkRowInputErrors = (errors) => {
        const values = []
        const newItems = []
        rowInputItems
            .filter((item) => item.view === 'edit' || item.view === 'show/edit')
            .map((item) => newItems.push(item))

        newItems.map((filterItem) => {
            values.push({
                id: filterItem.id,
                type: filterItem.def_dossier_def_field
                    ? filterItem.def_dossier_def_field.def_field.type
                    : filterItem.def_dossier_link,
                error: errors ? errors[filterItem.id] : errors[filterItem.id],
            })
        })

        const focusItem = values.find((item) => item.error)

        if (focusItem) {
            return focusItem.id
        }
    }

    const addRowInputDossier = () => {
        setRowInputSubmitting(true)

        const values = getCurrentRef().current.getValues()
        if (props.embedded && props.embedded !== true) {
            values['embedded'] = props.embedded
            values['linkId'] = props.linkId
        }

        values.defDossier = rowInputDd.id

        const customPosition = {
            id: null,
            display_items: rowInputItems,
        }

        const submitErrors = EntityHelper.checkCanSubmitDisplayPosition(
            kedo,
            rowInputDd,
            customPosition,
            getCurrentRef(),
            values,
            false
        )

        if (Object.keys(submitErrors).length > 0) {
            setRowInputErrors(submitErrors)
            setRowInputSubmitting(false)
            const errorId = checkRowInputErrors(submitErrors)
            getCurrentRef().current.doFocus(errorId)
            return
        }

        const saveUrl =
            kedo.api().getContentDossierEndpoint() + '?customView=row_input'
        const toastOptions = {}

        kedo.api()
            .post(saveUrl, values)
            .then((response) => {
                setRowInputContent({})
                setRowInputSubmitting(false)
                setRowInputErrors([])
                getRowInputDisplayFields()
                if (refreshResults) {
                    refreshResults()
                }
                toast.success(
                    kedo.t('dossier_errors.success_create', {
                        defdossier: kedo
                            .env()
                            .translateItem(rowInputDd, 'defdossier'),
                    }),
                    toastOptions
                )
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    setRowInputErrors(error.response.data.errors)
                    setRowInputSubmitting(false)
                } else if (error.response && error.response.status === 403) {
                    //setDenied_error(kedo.t('not_allowed_to_create_dossier'))
                    setRowInputSubmitting(false)

                    toast.error(
                        kedo.t('dossier_errors.permission_create'),
                        toastOptions
                    )
                    window.scrollTo(0, 0)
                } else {
                    toast.error(
                        kedo.t('dossier_errors.error_create'),
                        toastOptions
                    )
                    setRowInputErrors(error.response.data.errors)
                    setRowInputSubmitting(false)

                    const errorId = checkRowInputErrors(false)
                    getCurrentRef().current.doFocus(errorId)
                }
            })
    }

    const getRowInputDisplayFields = async () => {
        setLoadingRowInput(true)
        const ddiParams = {
            view: 'row_input',
            defDossier: props.item.def_dossier_link.child_def_dossier_id,
            sort: ['rank', 'col'],
            sortOrder: ['ASC', 'ASC'],
        }

        const displayItems = await entityService.getDisplayFields(
            props.item.def_dossier_link.child_def_dossier_id,
            ddiParams
        )
        const inputDd = await entityService.getEntity(
            props.item.def_dossier_link.child_def_dossier_id
        )
        setRowInputDd(inputDd)

        const newDisplayItems = []
        for (let i = 0; i < displayItems.results.length; i++) {
            const clone = { ...displayItems.results[i] }
            clone.view = 'edit'
            newDisplayItems.push(clone)
        }

        setRowInputItems(newDisplayItems)
        setLoadingRowInput(false)
    }

    if (rowInput && !rowInputInitialized && defDossierId) {
        setRowInputInitialized(true)
        getRowInputDisplayFields()
    }

    const onRowInputChangeValue = (itemId, value, item) => {
        setRowInputValues(value)
    }

    if (loadingRowInput) {
        return (
            <thead>
                <th colSpan={listItems.length + extraColumns + 1} />
            </thead>
        )
    }

    return (
        <thead>
            {rowInput === true &&
            rowInputInitialized &&
            rowInputItems.length > 0 ? (
                <tr>
                    <th colSpan={listItems.length + extraColumns + 1}>
                        <div>
                            {!rowInputInitialized ||
                            (loadingRowInput && rowInputItems.length <= 0) ? (
                                <Loading />
                            ) : (
                                <DefaultGrid
                                    changeValue={onRowInputChangeValue}
                                    conditions={[]}
                                    content={rowInputContent}
                                    embedded={props.embedded}
                                    linkId={props.linkId}
                                    errors={rowInputErrors}
                                    hiddenfields={[]}
                                    items={rowInputItems}
                                    kedo={kedo}
                                    onKeyPress={(item, keyEvent) => {
                                        if (keyEvent.key === 'Enter') {
                                            addRowInputDossier()
                                        }
                                    }}
                                    custom_layout={'row_input'}
                                    mode={'edit'}
                                    ref={getCurrentRef()}
                                    customButtons={() => {
                                        return (
                                            <Button
                                                disabled={rowInputSubmitting}
                                                type="submit"
                                                onClick={() =>
                                                    addRowInputDossier()
                                                }
                                                title="Create"
                                                variant="primary"
                                            >
                                                {rowInputSubmitting ? (
                                                    <LoadingDefault
                                                        size={'sm'}
                                                        as={'span'}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faSave}
                                                    />
                                                )}
                                                {kedo.t('Add')}
                                            </Button>
                                        )
                                    }}
                                />
                            )}
                        </div>
                    </th>
                </tr>
            ) : null}
            <tr>
                <th colSpan={listItems.length + extraColumns + 1}>
                    <div
                        className={`d-flex ${
                            selected.length
                                ? 'justify-content-between'
                                : 'justify-content-start'
                        } align-items-center`}
                    >
                        {selected.length > 0 ? (
                            <ActionButtons
                                selected={selected}
                                selectedDossiers={selectedDossiers}
                                isAllowedDd={isAllowedDd}
                                hasSubscriptions={hasSubscriptions}
                                dearchivedDossiers={dearchivedDossiers}
                                archivedDossiers={archivedDossiers}
                                kedo={kedo}
                                props={props}
                                mode={mode}
                                dossiers={dossiers}
                                pager={pager}
                                limit={limit}
                                handleEntriesChange={handleEntriesChange}
                                onDeleteSuccess={onDeleteSuccess}
                                defDossierId={defDossierId}
                                getSearchFilter={getSearchFilter}
                                fetchDossierResults={fetchDossierResults}
                                subscriptions={subscriptions}
                                favorites={favorites}
                                contents={contents}
                            />
                        ) : null}
                    </div>
                </th>
            </tr>
            {!dossiers || dossiers.length <= 0 ? (
                <Alert variant={'info'} style={{ margin: '0 17px' }}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {kedo.t('No items found')}
                </Alert>
            ) : (
                <tr>
                    <th style={{ width: '3%' }}>
                        {dossiers.length > 0 ? (
                            <input
                                checked={selected.length === dossiers.length}
                                onChange={checkAll}
                                type="checkbox"
                                style={{ marginRight: '0px' }}
                            />
                        ) : null}
                    </th>
                    {props.showEmail === true ? <th /> : null}
                    <th style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faBell} />
                    </th>
                    {listItems.map((listItem) => {
                        const isSortableField = isSortable(listItem)

                        //Item is not sortable
                        if (!isSortableField) {
                            return (
                                <th data-ddiid={listItem.id} key={listItem.id}>
                                    {kedo
                                        .env()
                                        .translateItem(listItem, 'displayitem')}
                                    <AdminQuickEditMenu
                                        className={'list-admin-edit-buttons'}
                                        kedo={kedo}
                                        item={listItem}
                                    />
                                </th>
                            )
                        }

                        let sortItem = null
                        if (
                            listItem.def_dossier_def_field &&
                            listItem.def_dossier_def_field.id
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.def_dossier_def_field &&
                                    itList.item.def_dossier_def_field.id ===
                                        listItem.def_dossier_def_field.id
                            )
                        } else if (
                            listItem.def_dossier_link &&
                            listItem.def_dossier_link.id
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.def_dossier_link &&
                                    itList.item.def_dossier_link.id ===
                                        listItem.def_dossier_link.id
                            )
                        } else if (
                            listItem.settings &&
                            listItem.settings.dossier_data
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.settings &&
                                    listItem.settings.dossier_data ===
                                        itList.item.settings.dossier_data
                            )
                        }

                        //Item is sortable
                        return (
                            <th data-ddiid={listItem.id} key={listItem.id}>
                                <span
                                    onClick={(e) =>
                                        applySort(
                                            e,
                                            listItem,
                                            sortItem && sortItem.order === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    {kedo.translateItem(
                                        listItem,
                                        'displayitem'
                                    )}{' '}
                                    &nbsp;
                                    {sortItem ? (
                                        <FontAwesomeIcon
                                            icon={
                                                sortItem.order === 'ASC'
                                                    ? faSortUp
                                                    : faSortDown
                                            }
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className={'text-muted'}
                                            icon={faSort}
                                        />
                                    )}
                                    &nbsp;&nbsp;
                                </span>
                                {sortItem ? (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={(e) => clearSort(e, listItem)}
                                        size={'sm'}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : null}
                                <AdminQuickEditMenu
                                    className={'list-admin-edit-buttons'}
                                    kedo={kedo}
                                    item={listItem}
                                />
                            </th>
                        )
                    })}
                    {actionBtns.length > 0 ? (
                        <th>{kedo.t('Actions')}</th>
                    ) : null}
                    <th />
                    <th />
                </tr>
            )}
        </thead>
    )
}

export default ContentDossierIndexHeader
