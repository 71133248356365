import { kedoInstance as Kedo } from '../Kedo.js'

class FieldPathHelper {
    static async getFieldData(originDossierId: number, fieldPath: string) {
        try {
            const responseDossierData = await Kedo.api().get(
                Kedo.api().getContentDossierEndpoint(originDossierId)
            )

            const item = responseDossierData.data.items.find(
                (item) =>
                    item?.def_dossier_def_field?.name === fieldPath ||
                    item?.def_dossier_link?.name === fieldPath
            )

            if (!item) {
                return null
            }

            let content = Object.prototype.hasOwnProperty.call(
                responseDossierData.data.content,
                item.id
            )
                ? responseDossierData.data.content[item.id]
                : null

            if (item?.def_dossier_link) {
                content = content?.map((item) => item.id)
            }

            return content
        } catch (error) {
            console.error('Error fetching data:', error)
            throw error
        }
    }
}

export default FieldPathHelper
