import * as React from 'react'
import { Component, useEffect, useState, useContext } from 'react'
import { EventsType, useIdleTimer } from 'react-idle-timer'
import { KeDoContext } from '../../kedo-context.js'
import Kedo from '../../Kedo.js'
import IdleTimeoutPrompt from './IdleTimeoutPrompt.jsx'
import LoginModal from '../LoginModal.jsx'

interface TimeoutProps {
    props: any
    toggleBlur: any
    handleLogin: (data: any, callBack: any, idleTimer: any) => void
}

const Timeout = ({ props, handleLogin, toggleBlur }: TimeoutProps) => {
    const kedo: Kedo = useContext(KeDoContext);
    const debounce = 250;
    const crossTab = true;
    const syncTimers = 50;
    const timeout = 1000 * 60 * 15
    const promptBeforeIdle = 1000 * 60 * 5

    //Enable for focus
    const events: EventsType[] = [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
        'visibilitychange',
        'focus',
    ]

    // Modal open state
    const [open, setOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [remaining, setRemaining] = useState(0);

    const isIgnoredOnPage = () => {
        return (
            window.location.pathname === '/login' ||
            window.location.pathname === '/forgot_password'
        );
    };

    const closePrompt = (resetTimeout = null) => {
        setOpen(false);
        setRemaining(0);
        setShowLoginModal(false);
        toggleBlur ? toggleBlur?.disable() : null;
        resetTimeout ? reset() : null;
    };

    const onPrompt = (e) => {
        if (isIgnoredOnPage()) {
            return;
        }
        toggleBlur ? toggleBlur?.enable() : null;
        setOpen(true);
        setRemaining(promptBeforeIdle);

        if (kedo) {
            kedo.api().checkTokenForExpiryAndRenew();
        }
    };

    const onIdle = (e) => {
        if (isIgnoredOnPage()) {
            return;
        }
        setOpen(false);
        setRemaining(0);
        toggleBlur ? toggleBlur?.enable() : null;
        setShowLoginModal(true);
    };

    const onActive = (e) => {
        if (open) {
            closePrompt(true);
        }
    };

    const onAction = (e) => {
        if (open === true && isIgnoredOnPage()) {
            closePrompt(true);
        }

        if (open === true && !isIgnoredOnPage()) {
            closePrompt(true);
            if (kedo) {
                kedo.api().checkTokenForExpiryAndRenew();
            }
        }
    };

    //This gets created too soon?
    //Todo: need to create later
    const { getRemainingTime, isPrompted, reset } = useIdleTimer({
        timeout,
        promptBeforeIdle,
        debounce,
        crossTab,
        syncTimers,
        events,
        onPrompt,
        onIdle,
        onActive,
        onAction,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime, isPrompted]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isIgnoredOnPage()) {
                reset();
                setOpen(false);
                setRemaining(0);
                setShowLoginModal(false);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [open]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!kedo.user().isLoggedIn()) {
                return;
            }
    
            kedo.api().checkTokenForExpiryAndRenew();
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    //This does not work if we waited too long and the original token is expired!
    useEffect(() => {
        window.addEventListener('focus', () => kedo.api().checkTokenForExpiryAndRenew());

        return () => {
            window.removeEventListener('focus', () => kedo.api().checkTokenForExpiryAndRenew());
        };
    }, []);

    return (
        <>
            {open && !isIgnoredOnPage() && !showLoginModal ? (
                <IdleTimeoutPrompt kedo={kedo} promptTimeout={promptBeforeIdle} remaining={remaining} />
            ) : null}
            {showLoginModal && !isIgnoredOnPage() ? (
                <LoginModal
                    handleLogin={(data) => {
                        try {
                            handleLogin(data, null, reset);
                            setShowLoginModal(false);
                        } catch (err) {
                            setShowLoginModal(true);
                        }
                    }}
                    kedo={kedo}
                />
            ) : null}
        </>
    );
};

export default Timeout;
