import * as React from 'react'
import SelectRC, { Option } from 'rc-select/lib/index.js'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons'
import useCompany from '../../util/useCompany'

interface CompanySearchFieldProps {
    changeValue: (itemId: any, value: any, item: any) => void
    conditions: any[]
    custom_layout?: any
    embedded?: any
    errors: any | null
    isNewDossier: boolean
    item: {
        id: number
        default_locale: string
        translations: any[]
        display_position_id: number
        locked: boolean
        def_dossier_id: number
        settings: any
    }
    kedo: any
    linkId?: any
    mode: 'edit' | 'view'
    onKeyPress?: ((event: React.KeyboardEvent) => void) | null
    recheckConditions: () => void
    shoudListenForConditions: boolean
    type: string
    value?: any
}

const CompanySearchField = React.forwardRef<any, CompanySearchFieldProps>(
    (props, ref) => {
        const [fieldValue, setFieldValue] = React.useState(props.value)
        const innerRef = React.useRef<any>(null)
        const { autocomplete, companies } = useCompany()

        React.useImperativeHandle(ref, () => ({
            getValue: () => fieldValue,
            setVal: (name, value) => setFieldValue(value),
        }))

        const onSelect = (itemSelected) => {
            const [idx, value] = itemSelected.split('-')
            console.log(idx)

            setFieldValue(value)
            setTimeout(() => {
                const company = companies[idx] || null
                props?.changeValue(props.item.id, company, props.item)
            }, 100)
        }

        const onClear = () => {
            setFieldValue(null)
            setTimeout(() => {
                props?.changeValue(props.item.id, null, props.item)
            }, 100)
        }

        const onSearch = (searchValue) => {
            if (props.item?.settings?.kvkNummer == props.item.id) {
                autocomplete({
                    kedo: props.kedo,
                    settings: {
                        kvkNummer: searchValue,
                    },
                })
            }
        }

        const onChange = (value) => {
            setFieldValue(value)
        }

        React.useEffect(() => {
            if (props.value) {
                autocomplete({
                    kedo: props.kedo,
                    settings: {
                        kvkNummer: props.value,
                    },
                })
            }
        }, [])

        return (
            <Row>
                <Col md={12} className="pl-3">
                    <SelectRC
                        ref={innerRef}
                        className="w-100 custom-select-react"
                        allowClear
                        placeholder={props.kedo.t('Search')}
                        value={fieldValue}
                        mode="combobox"
                        onChange={onChange}
                        onSearch={onSearch}
                        onSelect={onSelect}
                        onClear={onClear}
                        filterOption={false}
                        suffixIcon={
                            <FontAwesomeIcon size="xl" icon={faCaretDown} />
                        }
                        notFoundContent={props.kedo.t('No items found')}
                    >
                        {companies.map((company, idx) => (
                            <Option
                                key={`${idx}-${company.kvkNummer}`}
                                value={`${idx}-${company.kvkNummer}`}
                                label={company.kvkNummer}
                            >
                                {`${idx}-${company.kvkNummer} - ${company.naam} - ${company.vestigingsnummer}`}
                            </Option>
                        ))}
                    </SelectRC>
                </Col>
            </Row>
        )
    }
)

CompanySearchField.displayName = 'CompanySearchField'

export default CompanySearchField
