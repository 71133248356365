import { useState, useCallback } from 'react'

interface CompanyProps {
    kedo: any
    settings: any
}

const useCompany = () => {
    const [companies, setCompanies] = useState<Record<string, any>>([])
    const endpoint = '/kvk/search'

    const autocomplete = useCallback(({ kedo, settings }: CompanyProps) => {
        if (!settings) {
            return
        }

        const params = new URLSearchParams(settings).toString()

        kedo.api()
            .post(`${endpoint}?${params}`, {
                environment_id: kedo.getCurrentEnvironmentId(),
            })
            .then((response) => {
                if (response.data?.resultaten) {
                    setCompanies(response.data.resultaten)
                } else {
                    setCompanies([])
                }
            })
            .catch(() => {
                setCompanies([])
            })
    }, [])

    return { companies, autocomplete }
}

export default useCompany
