import { useEffect, useState } from 'react'
import * as React from 'react'
import { Alert, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import DisplayItemHelper from '../../../util/DisplayItemHelper'
import LoadingData from '../../../Components/Elements/Loading/LoadingData.jsx'
import {
    IDossierList,
    useDossierService,
} from '../../../context/DossierServiceContext'
import { useEntityService } from '../../../context/EntityServiceContext'
import ContentDossierIndexBody from './ContentDossierIndexBody'
import ContentDossierIndexFooter from './ContentDossierIndexFooter'
import ContentDossierIndexHeader from './ContentDossierIndexHeader'
import SearchDefDossier from '../../../Components/Pages/DefDossier/SearchDefDossier.jsx'
import DossierModalEditButton from '../../../Components/Elements/DossierModalEditButton.jsx'
import ExpiredModal from '../../../Components/Pages/Upgrade/ExpiredModal.jsx'
import { useSocketService } from '../../../context/SocketServiceContext'

interface ContentDossierIndexProps {
    props: any
    kedo: any
    forwardedRef?: any
}

export interface ContentDossierIndexHandle {
    fetchNewDossier(dossierId, isNew?): void
    doSearch(searchParams, dossierQuery, archived?): void
    onChangePage(number): void
    fetchDossierResults(IgnoreInitialized, callBack?: any): void
    refreshResults(IgnoreInitialized, callBack?: any): void
}

const ContentDossierIndex: React.ForwardRefRenderFunction<
    ContentDossierIndexHandle,
    ContentDossierIndexProps
> = ({ props, kedo, forwardedRef }) => {
    const savedLimit = kedo.utils().getSavedLimit(props.defDossierId)
    const dossierService = useDossierService()
    const socketService = useSocketService()
    const entityService = useEntityService()
    const [loadingContent, setLoadingContent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [inlineEdit, setInlineEdit] = useState(false)
    const [quickEdit, setQuickEdit] = useState<boolean>(false)
    const [quickShow, setQuickShow] = useState<boolean>(false)
    const [error, setError] = useState(false)
    const [expired, setExpired] = useState(false)
    const [showArchived, setShowArchived] = useState(false)
    const [archived, setArchived] = useState(null)
    const [pager, setPager] = useState({})
    const [searchItems, setSearchItems] = useState([])
    const [listItems, setListItems] = useState([])
    const [tabsAllowed, setTabsAllowed] = useState([])
    const [dossiers, setDossiers] = useState([])
    const [contents, setContents] = useState([])
    const [newDosIds, setNewDosIds] = useState([])
    const [actionBtns, setActionBtns] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [favorites, setFavorites] = useState([])
    const [socketDossierIds, setSocketDossierIds] = useState([])
    const [aggregations, setAggregations] = useState([])
    const [resultAggregations, setResultAggregations] = useState({})
    const [selected, setSelected] = useState([])
    const [sort, setSort] = useState([])
    const [isInitialized, setIsInitialized] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [limit, setLimit] = useState(
        props.limit && props.limit.length > 0
            ? props.limit
            : savedLimit?.pager?.limit
            ? savedLimit?.pager?.limit
            : 50
    )
    const [page, setPage] = useState(
        savedLimit?.pager?.current ? savedLimit?.pager?.current : 0
    )
    const [advancedSearch, setAdvancedSearch] = useState(null)
    const [searchParams, setSearchParams] = useState([])

    const [dossierQuery, setDossierQuery] = useState(
        props.dossierQuery ? props.dossierQuery : null
    )

    const [customView, setCustomView] = useState(
        props.customView ? props.customView : null
    )

    React.useImperativeHandle(forwardedRef, () => ({
        fetchNewDossier(dossierId, isNew) {
            fetchNewDossier(dossierId, isNew)
        },
        doSearch(searchVals, dossierQuery, archived) {
            doSearch(searchVals, dossierQuery, archived)
        },
        onChangePage(number) {
            onChangePage(number)
        },
        refreshResults(isInit, callBack) {
            refreshResults(isInit, callBack)
        },
    }))

    const fetchNewDossier = (dossierId, isNew = true) => {
        if (isNew) {
            //let newSocketDossiers = socketDossierIds
            socketDossierIds.push(dossierId)
        }

        const filter = getSearchFilter()

        if (aggregations && aggregations.length > 0) {
            for (let i = 0; i < aggregations.length; i++) {
                filter[aggregations[i].id + '_aggregate'] =
                    aggregations[i].aggregate
            }
        }

        filter.archived = archived

        filter.dossierId = dossierId

        if (props.view) {
            filter.view = props.view
        }

        const originalResults = {
            contents: contents,
            dossiers: dossiers,
        }
        kedo.api()
            .get(kedo.api().getContentDossierEndpoint(), {
                params: filter,
            })
            .then((response) => {
                const newContents = [
                    ...originalResults.contents,
                    ...response.data.results.contents,
                ]
                originalResults.contents = newContents

                const newDossiers = [
                    ...response.data.results.dossiers,
                    ...originalResults.dossiers,
                ]
                originalResults.dossiers = newDossiers

                setError(false)
                setDossiers(newDossiers)
                setContents(newContents)
                //results: originalResults;
            })
    }

    const handleDossierModal = (remove, close) => {
        if (remove) {
            fetchDossierResults()
            setQuickEdit(false)
            setQuickShow(true)
            setInlineEdit(false)
        }

        if (close) {
            setInlineEdit(false)
            setQuickEdit(false)
        }
    }

    const checkPermissions = async (dossierItems) => {
        if (dossierItems.length <= 0) {
            return
        }
        const data = {
            defDossier: props.defDossierId,
            ids: dossierItems.map((dossier) => dossier.id),
        }
        kedo.api()
            .post(kedo.api().getDisplayPositionCredentialsEndpoint(), data)
            .then((response) => {
                setTabsAllowed(response.data)
            })
    }

    const refreshResults = async (addData: boolean, callBack?: any) => {
        try {
            setIsRefreshing(true)
            const filter = getSearchFilter()
            if (aggregations && aggregations.length > 0) {
                for (let i = 0; i < aggregations.length; i++) {
                    filter[aggregations[i].id + '_aggregate'] =
                        aggregations[i].aggregate
                }
            }

            filter.archived = archived

            if (customView) {
                filter.view = customView
            }

            if (props.view) {
                filter.view = props.view
            }

            const dossierList = (await dossierService.getDossiers(
                filter
            )) as IDossierList

            if (props.callBackPager) {
                props.callBackPager(dossierList.pager)
            } else {
                setPager(dossierList.pager)
            }

            const dosIds = []
            const newIds = []
            const newDossiers = []
            const newContents = []
            for (let i = 0; i < dossiers.length; i++) {
                newDossiers.push(dossiers[i])
                dosIds.push(dossiers[i].id)
                for (let b = 0; b < contents.length; b++) {
                    if (contents[b].id === dossiers[i].id) {
                        newContents.push(contents[b])
                    }
                }
            }

            for (let i = 0; i < dossierList.dossiers.length; i++) {
                //Check if dossier already exists
                if (dosIds.includes(dossierList.dossiers[i].id)) {
                    continue
                }
                newIds.push(dossierList.dossiers[i].id)
                newDossiers.push(dossierList.dossiers[i])
                for (let b = 0; b < dossierList.contents.length; b++) {
                    if (
                        dossierList.contents[b].id ===
                        dossierList.dossiers[i].id
                    ) {
                        newContents.push(dossierList.contents[b])
                    }
                }
            }

            setDossiers(newDossiers.sort((a, b) => b.id - a.id))
            setContents(newContents)
            setNewDosIds(newIds)

            for (let i = 0; i < newIds.length; i++) {
                socketDossierIds.push(newIds[i])
                tabsAllowed.push({ id: newIds[i], tabs_allowed: true })
            }

            setTimeout(() => {
                for (let i = 0; i < newIds.length; i++) {
                    const sockIndex = socketDossierIds.indexOf(newIds[i])
                    if (sockIndex > -1) {
                        socketDossierIds.splice(sockIndex, 1)
                    }
                }
            }, 30000)

            //TODO: Aggregations cannot work because we are adding new dossiers, or maybe they can??
            setResultAggregations(dossierList.aggregations)
            setIsRefreshing(false)
        } catch (error) {
            setError(true)
            throw error
        } finally {
            setIsRefreshing(false)
            if (callBack) {
                callBack()
            }
        }
    }

    const fetchDossierResults = async (
        IgnoreInitialized?: boolean,
        callBack?: any
    ) => {
        if (!isInitialized && IgnoreInitialized !== true) {
            return
        }

        setLoadingContent(true)

        try {
            const filter = getSearchFilter()
            if (aggregations && aggregations.length > 0) {
                for (let i = 0; i < aggregations.length; i++) {
                    filter[aggregations[i].id + '_aggregate'] =
                        aggregations[i].aggregate
                }
            }

            filter.archived = archived

            if (customView) {
                filter.view = customView
            }

            if (props.view) {
                filter.view = props.view
            }

            const dossierList = (await dossierService.getDossiers(
                filter
            )) as IDossierList

            if (props.callBackPager) {
                props.callBackPager(dossierList.pager)
            } else {
                setPager(dossierList.pager)
            }

            setDossiers(dossierList.dossiers)
            setContents(dossierList.contents)
            setResultAggregations(dossierList.aggregations)
            setListItems(
                DisplayItemHelper.filterViews(
                    dossierList.items.filter(
                        (item) =>
                            !DisplayItemHelper.isTypeOfField(
                                item,
                                DisplayItemHelper.FIELD_CUSTOMACTIONBTN_TYPE
                            )
                    ),
                    [
                        props.customView
                            ? props.customView
                            : props.view
                            ? props.view
                            : DisplayItemHelper.LIST_VIEW,
                    ]
                )
            )
            setActionBtns(
                dossierList.items.filter(
                    (item) =>
                        DisplayItemHelper.isTypeOfField(
                            item,
                            DisplayItemHelper.FIELD_CUSTOMACTIONBTN_TYPE
                        ) === true
                )
            )
            checkPermissions(dossierList.dossiers)
        } catch (error) {
            setError(true)
            throw error
        } finally {
            setLoadingContent(false)
        }
    }

    const onDeleteSuccess = () => {
        setSelected([])

        socketService.displayItem.delete({
            defDossierId: props.defDossierId,
            sender: socketService.socket()?.id,
            room:
                window.location.pathname.split('/')[1] !== 'contentdossier'
                    ? 'environment:' + kedo.env().getEnvironmentId()
                    : null,
        })

        fetchDossierResults()
    }

    const loadSocketCallbacks = () => {
        socketService.displayItem.fetchListener((data) => {
            if (
                socketService.socket().id !== data.sender.id &&
                data.defDossierId === props.defDossierId
            ) {
                fetchDossierResults(true)
            }
        })
    }

    const doSearch = (searchVals, dossierQuery, archived) => {
        setArchived(archived)
        setPage(0)
        setSearchParams([...searchVals])
        setDossierQuery(dossierQuery ?? 'reset')
    }

    useEffect(() => {
        fetchDossierResults()

        props && props.embedded ? loadSocketCallbacks() : null

        return () => {
            props && props.embedded
                ? socketService.displayItem.removeDdiListeners()
                : null
        }
    }, [
        limit,
        page,
        JSON.stringify(aggregations),
        dossierQuery,
        sort,
        searchParams,
        isInitialized,
    ])

    useEffect(() => {
        fetchSubscriptions()
        checkPermissions(dossiers)
    }, [dossiers])

    const handleEntriesChange = (event) => {
        setLimit(event.target.value)
        setPage(0)
    }

    const canBeArchived = () => {
        const dds = kedo.env().getCurrentEnvironmentDefDossiers()
        if (dds.length <= 0) {
            return false
        }

        return (
            dds.filter(
                (item) =>
                    item.id === props.defDossierId && item.archiveable === true
            ).length > 0
        )
    }

    const updateCheckedStatus = (event, dossier) => {
        const newSelected = selected.filter((checked) => checked !== dossier.id)

        if (event.target.checked === true) {
            newSelected.push(dossier.id)
        }

        setSelected(newSelected)
    }

    const clearSort = (e, listItem): void => {
        e.preventDefault()

        if (listItem.def_dossier_link) {
            const sortFields = sort.filter(
                (liItem) =>
                    liItem.item.def_dossier_link?.id !=
                    listItem.def_dossier_link.id
            )
            setSort(sortFields)

            return
        }

        //Clear dossier column sorts.
        if (listItem.settings && listItem.settings.dossier_data) {
            const sortFields = sort.filter(
                (liItem) =>
                    liItem.item.setttings &&
                    liItem.item.settings.dossier_data !==
                        listItem.settings.dossier_data
            )
            setSort(sortFields)

            return
        }

        //Clear normal data fields.
        if (!listItem && !listItem.def_dossier_def_field) {
            return
        }

        const sortFields = sort.filter(
            (liItem) =>
                liItem.item.def_dossier_def_field.id !=
                listItem.def_dossier_def_field.id
        )
        setSort(sortFields)
    }

    const applySort = (e, listItem, listOrder): void => {
        e.preventDefault()

        if (
            listItem &&
            listItem.settings &&
            listItem.settings.dossier_data &&
            (listItem.settings.dossier_data === 'created_at' ||
                listItem.settings.dossier_data === 'modified_at')
        ) {
            const sortFields = sort.filter(
                (liItem) =>
                    !liItem.item ||
                    !liItem.item.settings ||
                    !liItem.item.settings.dossier_data ||
                    liItem.item.settings.dossier_data !==
                        listItem.settings.dossier_data
            )
            sortFields.push({ item: listItem, order: listOrder })
            setSort(sortFields)

            return
        }

        if (
            !listItem &&
            !listItem.def_dossier_def_field &&
            !listItem.def_dossier_link
        ) {
            return
        }

        const sortFields = sort.filter((liItem) => {
            if (
                listItem.def_dossier_def_field &&
                liItem.item.def_dossier_def_field &&
                liItem.item.def_dossier_def_field.id !=
                    listItem.def_dossier_def_field.id
            ) {
                return false
            }

            if (
                listItem.def_dossier_link &&
                liItem.item.def_dossier_link &&
                liItem.item.def_dossier_link.id != listItem.def_dossier_link.id
            ) {
                return false
            }
        })

        sortFields.push({ item: listItem, order: listOrder })
        setSort(sortFields)
    }

    const onChangePage = (number) => {
        setPage(number)
    }

    const saveCurrentParams = (limit, number, defDossierId) => {
        const kedoUtils = kedo.utils()

        const pageItem = {
            pager: pager,
            limit: limit,
            current: number,
            defDossierId: props.defDossierId,
        }

        if (limit && limit.length > 0) pageItem.limit = limit
        if (number && number.length > 0) pageItem.current = number

        if (pageItem.limit || pageItem.current) {
            kedoUtils.createSavedLimit(pageItem)
        } else {
            kedoUtils.deleteSavedLimit(defDossierId)
        }
    }

    useEffect(() => {
        saveCurrentParams(limit, page, props.defDossierId)
    }, [onChangePage])

    const fetchSearchDisplayItems = async () => {
        if (loading) {
            return
        }

        const listResults = await entityService.getDisplayFields(
            props.defDossierId,
            { limit: 100, view: ['search', 'advanced-search'] }
        )

        setSearchItems(listResults.results)
        setLoading(false)
    }

    const isSelected = (dossier) => {
        return selected.find((selectedId) => selectedId === dossier.id)
    }

    const fetchSubscriptions = async () => {
        if (!dossiers || dossiers.length <= 0) {
            return
        }
        const response = await kedo
            .api()
            .post(kedo.api().getContentDossierEndpoint() + '/subscriptions', {
                ids: dossiers.map((item) => item.id),
            })
        setSubscriptions(response.data)
    }

    const getSearchFilter = () => {
        const filter = {
            defDossier: props.defDossierId,
            limit: limit,
            page: page,
            dossierQuery: null,
            sort: null,
            sortOrder: null,
            embedded: null,
            linkId: null,
            archived: null,
            dossierId: null,
            view: null,
        }

        if (dossierQuery === 'reset') {
            filter.dossierQuery = null
        } else if (dossierQuery || props.dossierQuery) {
            filter.dossierQuery = dossierQuery || props.dossierQuery
        }

        if (sort && sort.length > 0) {
            filter.sort = []
            filter.sortOrder = []
            sort.map((filterItem) => {
                if (filterItem.item.def_dossier_link) {
                    filter.sort.push(
                        'dfl_' + filterItem.item.def_dossier_link.id
                    )
                } else if (filterItem.item.def_dossier_def_field) {
                    filter.sort.push(filterItem.item.def_dossier_def_field.id)
                } else {
                    filter.sort.push(filterItem.item.settings.dossier_data)
                }

                filter.sortOrder.push(filterItem.order)
            })
        }

        if (props.embedded) {
            filter.embedded = props.embedded
            filter.linkId = props.linkId
        }

        if (searchParams && searchParams.length > 0) {
            searchParams.map((searchItem) => {
                if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'date'
                ) {
                    filter[searchItem.id + '_type'] = searchItem.value.type
                    if (searchItem.value.type === 'between') {
                        filter[searchItem.id + '_end'] = searchItem.value.date2
                    }
                    filter[searchItem.id] = searchItem.value.date1
                } else if (typeof searchItem.value === 'string') {
                    filter[searchItem.id] = searchItem.value
                } else if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'slider'
                ) {
                    filter[searchItem.id + '_min'] = searchItem.value.min
                    filter[searchItem.id + '_max'] = searchItem.value.max
                    filter[searchItem.id] = '1'
                } else if (searchItem.value instanceof Array) {
                    const values = []
                    searchItem.value.map((valItem) => values.push(valItem.id))
                    filter[searchItem.id] = values
                }
            })
        }

        return filter
    }

    const search = (values, dossierQuery) => {
        setSearchParams(values)
        setDossierQuery(dossierQuery)
        fetchDossierResults()
    }

    const renderSearchFilter = () => {
        if (
            searchItems.length <= 0 ||
            props.embedded ||
            props.initSearch === false
        ) {
            return null
        }
        const defDossier = kedo
            .env()
            .getCurrentEnvironmentDefDossiers()
            .find((item) => item.id === parseInt(props.defDossierId))

        return (
            <SearchDefDossier
                advancedSearch={advancedSearch}
                doSearch={doSearch}
                kedo={kedo}
                defDossierId={props.defDossierId}
                dossierQuery={dossierQuery}
                defDossier={defDossier}
                items={searchItems}
                showArchive={showArchived}
                resetSearch={() => setSearchParams([])}
                search={searchParams}
                location={'DefDossierIndex'}
            />
        )
    }

    useEffect(() => {
        setSort(
            DisplayItemHelper.mapSortSettings(
                props.ddiSort
                    ? props.ddiSort
                    : props.defDossier && props.defDossier.sort
                    ? props.defDossier.sort
                    : props.block?.settings?.sort || []
            )
        )
        setShowArchived(canBeArchived())

        const data = props.kedo.utils().getSavedSearch(props.defDossierId)

        if (data && !props.embedded) {
            setSearchParams(data && data.search ? [...data.search] : null)
            setArchived(data && data.archived ? data.archived : null)
            /*
            TODO: Disable remembering dossier queries for now, but needs to be fixed.
            setDossierQuery(
                props.dossierQuery
                    ? props.dossierQuery
                    : data && data.dossierQuery
                    ? data.dossierQuery
                    : null
            )
             */
            setAdvancedSearch(
                data && data.advancedSearch ? data.advancedSearch : null
            )
        }

        const pageData = props.kedo.utils().getSavedLimit(props.defDossierId)

        if (pageData) {
            setPager(pageData?.pager ? pageData.pager : null)
        }
        loadSocketCallbacks()

        const fetchData = async () => {
            await fetchSearchDisplayItems()
            await fetchSubscriptions()
            await setIsInitialized(true)
            await fetchDossierResults()
        }
        fetchData()
    }, [])

    if (loading) {
        return (
            <div className={'container-fluid'}>
                <LoadingData />
            </div>
        )
    }
    if (error !== false) {
        return (
            <div className={'col-md-12'}>
                {expired ? <ExpiredModal kedo={kedo} /> : null}
                <Alert variant={'warning'}>
                    <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                    {kedo.t('Something went wrong')}.
                </Alert>
            </div>
        )
    }

    const extraColumns = actionBtns.length > 0 ? 4 : 3

    const handlePermissions = () => {
        setQuickEdit(false)
        setQuickShow(true)
    }

    return (
        <div>
            {props?.mode !== 'block' ? renderSearchFilter() : null}
            {props?.defDossier?.disable_immediate_search ? (
                Array.isArray(searchParams) && searchParams.length > 0 ? (
                    <Table size="sm" striped hover responsive>
                        <ContentDossierIndexHeader
                            {...{
                                props,
                                rowInput: props.rowInput,
                                loadingContent,
                                kedo,
                                sort,
                                limit,
                                handleEntriesChange,
                                clearSort,
                                applySort,
                                mode: props.mode,
                                pager,
                                onDeleteSuccess,
                                subscriptions,
                                favorites,
                                getSearchFilter,
                                dossiers,
                                selected,
                                setSelected,
                                listItems,
                                actionBtns,
                                refreshResults,
                                fetchDossierResults,
                                defDossierId: props?.defDossierId,
                                showArchived,
                                contents,
                            }}
                        />
                        <ContentDossierIndexBody
                            {...{
                                kedo,
                                setSocketDossierIds,
                                contents,
                                props,
                                tabsAllowed,
                                refreshing: isRefreshing,
                                fetchDossierResults,
                                mode: props?.mode,
                                setInlineEdit,
                                loadingContent,
                                subscriptions,
                                extraColumns,
                                dossiers,
                                listItems,
                                actionBtns,
                                socketDossierIds,
                                onSelect: (event, dossier) =>
                                    updateCheckedStatus(event, dossier),
                                isSelected,
                                showEmail: props?.showEmail,
                                setQuickEdit,
                                setQuickShow,
                            }}
                        />
                        <ContentDossierIndexFooter
                            {...{
                                resultAggregations,
                                loadingContent,
                                listItems,
                                kedo,
                                dossiers,
                                extraColumns,
                                aggregations,
                                setAggregations,
                                onChangePage,
                                pager,
                                selected,
                                subscriptions,
                                defDossierId: props?.defDossierId,
                                props,
                                mode: props.mode,
                                limit,
                                handleEntriesChange,
                                onDeleteSuccess,
                                getSearchFilter,
                                fetchDossierResults,
                                favorites,
                                contents,
                                showArchived,
                            }}
                        />
                    </Table>
                ) : null
            ) : (
                <Table size="sm" striped hover responsive>
                    <ContentDossierIndexHeader
                        {...{
                            props,
                            rowInput: props.rowInput,
                            loadingContent,
                            kedo,
                            sort,
                            limit,
                            handleEntriesChange,
                            clearSort,
                            applySort,
                            mode: props.mode,
                            pager,
                            onDeleteSuccess,
                            subscriptions,
                            favorites,
                            getSearchFilter,
                            dossiers,
                            selected,
                            setSelected,
                            listItems,
                            actionBtns,
                            refreshResults,
                            fetchDossierResults,
                            defDossierId: props?.defDossierId,
                            showArchived,
                            contents,
                        }}
                    />
                    <ContentDossierIndexBody
                        {...{
                            kedo,
                            setSocketDossierIds,
                            contents,
                            props,
                            tabsAllowed,
                            refreshing: isRefreshing,
                            fetchDossierResults,
                            mode: props?.mode,
                            setInlineEdit,
                            loadingContent,
                            subscriptions,
                            extraColumns,
                            dossiers,
                            listItems,
                            actionBtns,
                            socketDossierIds,
                            onSelect: (event, dossier) =>
                                updateCheckedStatus(event, dossier),
                            isSelected,
                            showEmail: props?.showEmail,
                            setQuickEdit,
                            setQuickShow,
                        }}
                    />
                    <ContentDossierIndexFooter
                        {...{
                            resultAggregations,
                            loadingContent,
                            listItems,
                            kedo,
                            dossiers,
                            extraColumns,
                            aggregations,
                            setAggregations,
                            onChangePage,
                            pager,
                            selected,
                            defDossierId: props?.defDossierId,
                            props,
                            mode: props.mode,
                            limit,
                            handleEntriesChange,
                            onDeleteSuccess,
                            getSearchFilter,
                            fetchDossierResults,
                            subscriptions,
                            favorites,
                            contents,
                            showArchived,
                        }}
                    />
                </Table>
            )}
            {inlineEdit ? (
                <DossierModalEditButton
                    onDeleteSuccess={() => handleDossierModal(true, false)}
                    showSaveClose={true}
                    onSuccess={(close) => {
                        handleDossierModal(true, close)
                    }}
                    onClose={() => handleDossierModal(false, true)}
                    embedded={props.embedded}
                    edit={true}
                    dossierId={inlineEdit}
                    kedo={kedo}
                    quickEdit={quickEdit}
                    quickShow={quickShow}
                    handlePermissions={handlePermissions}
                />
            ) : null}
        </div>
    )
}

export default React.forwardRef(ContentDossierIndex)
