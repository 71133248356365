import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUserSlash } from '@fortawesome/pro-duotone-svg-icons/faUserSlash'
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons/faCaretDown'

import SelectRC, { Option } from 'rc-select'
import Avatar from '../Elements/Avatar.tsx'

const singleErrorStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            borderColor: '#dc3545',
            boxShadow: state.isFocused
                ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
                : 'transparant',
            ':hover': { borderColor: '#dc3545' },
        }
    },
}

class UserField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        let selected = []
        if (this.props.value?.length > 0) {
            this.props.value.map((item) => selected.push(item))
        }

        let defaultCurrent =
            this.props.item?.settings?.default_current_user === true &&
            this.props.mode !== 'search' &&
            this.props.mode !== 'advanced-search'
                ? true
                : false

        if (defaultCurrent && this.props.isNewDossier) {
            selected.push({
                id: this.props.kedo.user().getUserId(),
                username: this.props.kedo.user().getUsername(),
                label: this.props.kedo.user().getUsername(),
            })
        }

        let displayNameStyle =
            this.props.item &&
            this.props.item.settings &&
            this.props.item.settings.display_name_style === true &&
            this.props.mode !== 'search' &&
            this.props.mode !== 'advanced-search'
                ? true
                : false

        this.state = {
            default_current_user: defaultCurrent,
            display_name_style: displayNameStyle,
            options: [],
            optionsLoading: false,
            showInactive: true,
            page: 0,
            search: '',
            selected: selected,
            total: 0,
            value: this.props.value,
        }

        this.doFocus = this.doFocus.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.getOptions = this.getOptions.bind(this)
        this.getOption = this.getOption.bind(this)
    }

    onCancel() {
        this.setState({ selected: this.props.value })
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        return this.state.selected.map((item) => item.id)
    }

    userDisplay(item) {
        if (this.props.item.settings?.display_name_style === 'avatar') {
            return (
                <Avatar
                    key={item}
                    kedo={this.props.kedo}
                    user={{
                        userId: item.id,
                        username: item.text ? item?.text : this.getName(item),
                    }}
                    options={{
                        size: 25,
                        showPresence: false,
                    }}
                />
            )
        } else if (
            this.props.item.settings &&
            this.props.item.settings.display_name_style === 'both'
        ) {
            return (
                <>
                    <Avatar
                        key={item}
                        kedo={this.props.kedo}
                        user={{
                            userId: item.id,
                            username: item.text
                                ? item?.text
                                : this.getName(item),
                        }}
                        options={{
                            size: 25,
                            showPresence: false,
                        }}
                    />
                    &nbsp;
                    {item.text ? item?.text : this.getName(item)}
                </>
            )
        }

        return item.text ? item?.text : this.getName(item)
    }

    renderShow() {
        if (!this.state.selected || this.state.selected.length <= 0) {
            return null
        }

        if (this.state.selected.length === 1) {
            return (
                <span>
                    {this.state.selected.map((item) => (
                        <a
                            className={`${
                                this.props.item.settings?.display_name_style ===
                                'avatar'
                                    ? ' user-link '
                                    : ''
                            }`}
                            key={item.id}
                            href={`/user/${item.id}`}
                        >
                            {/* {item.text} */}
                            {this.userDisplay(item)}
                        </a>
                    ))}
                </span>
            )
        }

        return (
            <ul>
                {this.state.selected.map((item) => (
                    <li style={{ listStyle: 'none' }} key={item.id}>
                        <a
                            className={`${
                                this.props.item.settings &&
                                this.props.item.settings.display_name_style ===
                                    'avatar'
                                    ? ' user-link '
                                    : ''
                            }`}
                            href={`/user/${item.id}`}
                        >
                            {/* {item.text} */}
                            {this.userDisplay(item)}
                        </a>
                    </li>
                ))}
            </ul>
        )
    }

    addSelectedItem(data) {
        let select = []

        if (data) select = Array.isArray(data) ? data : [{ ...data }]

        if (this.props.changeValue) {
            let vals = []

            select.forEach((user) => {
                vals.push({
                    id: user.id,
                })
            })

            this.props.changeValue(this.props.item.id, vals)
        }

        this.setState({
            selected: select,
        })
    }

    getOptions(inputValue = '', callBack) {
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 25,
            },
        }

        if (inputValue.length > 0) {
            params.params.name = inputValue
        }

        if (this.state.showInactive !== true) {
            params.params.active = true
        }

        this.props.kedo
            .api()
            .get(this.props.kedo.api().getUserEndpoint(), params)
            .then((response) => {
                const options = response.data.results.map((user) => ({
                    id: user.id,
                    value: user.id,
                    label: (
                        <span
                            style={
                                !user.environment_user_active
                                    ? { color: 'gray' }
                                    : null
                            }
                        >
                            {this.userDisplay(user)}
                            {!user.environment_user_active ? (
                                <FontAwesomeIcon icon={faUserSlash} />
                            ) : null}
                        </span>
                    ),
                }))

                this.setState({ options })

                if (callBack) {
                    callBack(options)
                }
            })
            .catch((error) => {
                console.error('Error fetching options:', error)
                if (callBack) callBack([])
            })
    }

    async getOption(userId) {
        const api = this.props.kedo.api()
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
            },
        }

        return await api
            .getAsync(api.getUserEndpoint(userId), params)
            .then((response) => {
                return {
                    id: userId,
                    value: userId,
                    label: (
                        <span>
                            {this.userDisplay({ id: userId, ...response.data })}
                        </span>
                    ),
                }
            })
    }

    async componentDidMount() {
        this.getOptions('', (options) => {
            this.setState({ options })
        })
        if (
            (this.props.mode === 'search' ||
                this.props.mode === 'advanced-search') &&
            !this.state.selectedOptions
        ) {
            const tempArray = []
            this.state.selected.forEach(async (item) => {
                tempArray.push(await this.getOption(item.id))
            })

            this.setState({ selectedOptions: tempArray })
        }
    }

    isSingleSelect() {
        let settings = this.props.item.def_dossier_def_field.def_field.settings
        if (settings && settings.multiple && settings.multiple === true) {
            return false
        }

        return true
    }

    getName(item) {
        if (item.text) {
            return item.text
        }

        if (item.label) {
            return item.label
        }

        if (!item.profile) {
            return `${item.lastname}, ${item.firstname}`
        }

        return (
            item.profile.last_name +
            ', ' +
            item.profile.first_name +
            (item.profile.preposition && item.profile.preposition.length > 0
                ? ' ' + item.profile.preposition
                : '')
        )
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    renderEdit() {
        let defaultValue = Array.isArray(this.state.selected)
            ? this.state.selected.map((item) => ({
                  id: item.id,
                  value: item.id,
                  label: this.getName(item),
              }))
            : []

        const filteredOptions = this.state.options.filter(
            (option) =>
                !defaultValue.some(
                    (selected) => selected.value === option.value
                )
        )

        return (
            <>
                <SelectRC
                    className="w-100 custom-select-react"
                    id={`${this.props.item.id}-search`}
                    mode={!this.isSingleSelect() ? 'multiple' : undefined}
                    allowClear
                    placeholder={this.props.kedo.t('Search') + '...'}
                    value={this.state.options.filter((option) =>
                        defaultValue.some((item) => item.value === option.value)
                    )}
                    onChange={(selectedItems) => {
                        const selectedArray = this.isSingleSelect()
                            ? selectedItems
                                ? [selectedItems]
                                : []
                            : selectedItems

                        const selectedOptions = selectedArray
                            .map((selectedItem) => {
                                const foundOption = this.state.options.find(
                                    (option) => option.value === selectedItem
                                )
                                return foundOption
                            })
                            .filter(Boolean)

                        this.addSelectedItem(selectedOptions)
                    }}
                    suffixIcon={
                        <FontAwesomeIcon size="xl" icon={faCaretDown} />
                    }
                    notFoundContent={this.props.kedo.t('No items found')}
                >
                    {filteredOptions.map((option) => (
                        <Option key={option.id} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </SelectRC>
                {this.isInvalid() ? (
                    <span className={'fake-feedback'}>{this.props.errors}</span>
                ) : null}
            </>
        )
    }

    renderSearch() {
        if (this.state.value !== this.props.value) {
            this.setState({ value: this.props.value })
        }

        if (!this.state.selectedOptions) {
            return null
        }

        const filteredOptions = this.state.options.filter(
            (option) =>
                !this.state.selected.some(
                    (selected) => selected.value === option.value
                )
        )

        return (
            <SelectRC
                className="w-100 custom-select-react"
                id={`${this.props.item.id}-search`}
                mode={this.isSingleSelect() ? undefined : 'multiple'}
                allowClear
                placeholder={this.props.kedo.t('Search') + '...'}
                value={
                    this.state.selected.length > 0
                        ? this.isSingleSelect()
                            ? {
                                  value: this.state.selected[0].value,
                                  label: this.state.selected[0].label,
                              }
                            : this.state.selected.map((item) => ({
                                  value: item.value,
                                  label: item.label,
                              }))
                        : undefined
                }
                onChange={(selectedItems) => {
                    let selectedOptions = []

                    if (this.isSingleSelect()) {
                        const foundOption = this.state.options.find(
                            (option) => option.value === selectedItems
                        )
                        if (foundOption) {
                            selectedOptions = [foundOption]
                        }
                    } else if (Array.isArray(selectedItems)) {
                        selectedOptions = selectedItems
                            .map((selectedItem) =>
                                this.state.options.find(
                                    (option) => option.value === selectedItem
                                )
                            )
                            .filter(Boolean)
                    }

                    this.addSelectedItem(selectedOptions)
                }}
                suffixIcon={<FontAwesomeIcon size="xl" icon={faCaretDown} />}
                notFoundContent={this.props.kedo.t('No items found')}
            >
                {filteredOptions.map((option) => (
                    <Option key={option.id} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </SelectRC>
        )
    }

    render() {
        if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return this.renderEdit()
        } else if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return this.renderSearch()
        }
        return this.renderShow()
    }
}

export default UserField
